import { Component, OnInit } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { CONTROL_TYPES } from '@root/app.config';

@Component({
	selector: 'app-control-switcher',
	templateUrl: './control-switcher.component.html',
	styleUrls: ['./control-switcher.component.scss'],
})
export class ControlSwitcherComponent implements OnInit {
	controlTypes = CONTROL_TYPES;
	selectedControlType = CONTROL_TYPES.move;

	constructor(private engineService: EngineService) {}

	ngOnInit(): void {
		this.engineService.setControl(this.selectedControlType);
	}

	onSwitchControlMode(event, type: string) {
		event.stopPropagation();
		this.selectedControlType = type;
		this.engineService.setControl(type);
	}
}
