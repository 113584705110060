<div class="mc-modal__content">
	<div class="mc-modal__icon">
		<svg *ngIf="!data['network'].status" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="70" cy="70" r="69.5" stroke="#C6C6C6" />
			<path
				d="M66.5359 46C68.0755 43.3333 71.9245 43.3333 73.4641 46L92.5167 79C94.0563 81.6667 92.1318 85 89.0526 85H50.9474C47.8682 85 45.9437 81.6667 47.4833 79L66.5359 46Z"
				fill="#F3CA3E"
			/>
			<path
				d="M67.4473 57.6885C67.2209 56.1328 68.4272 54.7383 69.9993 54.7383C71.5713 54.7383 72.7776 56.1328 72.5512 57.6885L70.4441 72.1671C70.412 72.3881 70.2225 72.5519 69.9993 72.5519C69.776 72.5519 69.5865 72.3881 69.5544 72.1671L67.4473 57.6885Z"
				stroke="#16416C"
			/>
			<path
				d="M71.4234 77.8832C71.4234 78.5557 70.8248 79.1629 70.0069 79.1629C69.189 79.1629 68.5903 78.5557 68.5903 77.8832C68.5903 77.2107 69.189 76.6035 70.0069 76.6035C70.8248 76.6035 71.4234 77.2107 71.4234 77.8832Z"
				stroke="#16416C"
			/>
		</svg>
	</div>
	<div class="mc-modal__title">
		{{ (data['network'].status ? 'modals.error.header' : 'modals.error.offline') | translate }}
	</div>
	<div class="mc-modal__subtitle">
		{{ (data['network'].status ? 'modals.error.description' : 'modals.error.offline_description') | translate }}
	</div>
	<div class="mc-modal__buttons" *ngIf="data['network'].status">
		<div class="mc-modal__button mc-modal__button--yellow" (click)="goHomeButton()">
			{{ 'modals.error.button' | translate }}
		</div>
	</div>
</div>
