import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { AppRoutingModule } from '../app-routing.module';
import { ErrorModalComponent } from '@shared//modals/error-modal/error-modal.component';
import { ToTopBtnComponent } from './to-top-btn/to-top-btn.component';
import { LeaveModalComponent } from '@shared//modals/leave-modal/leave-modal.component';
import { DeleteModalComponent } from '@shared//modals/delete-modal/delete-modal.component';
import { RetailerSuccessModalComponent } from '@shared//modals/retailer-success-modal/retailer-success-modal.component';
import { RetailerNoSelectModalComponent } from '@shared//modals/retailer-no-select-modal/retailer-no-select-modal.component';
import { RetailerSelectModalComponent } from '@shared//modals/retailer-select-modal/retailer-select-modal.component';
import { SaveWorkModalComponent } from '@shared//modals/save-work-modal/save-work-modal.component';
import { SelectBackgroundModalComponent } from '@shared//modals/select-background-modal/select-background-modal.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReactiveFormsModule } from '@angular/forms';
import { RotateDeviceModalComponent } from '@shared//modals/rotate-device-modal/rotate-device-modal.component';
import { ArLoadingModalComponent } from '@shared//modals/ar-loading-modal/ar-loading-modal.component';
import { QuizModalComponent } from '@shared//modals/quiz-modal/quiz-modal.component';
import { RetailerInfoModalComponent } from '@shared//modals/retailer-info-modal/retailer-info-modal.component';
import { RetailerInfoPopoverComponent } from '@shared//modals/retailer-info-popover/retailer-info-popover.component';
import { AugmentedRealityComponent } from '@shared/augmented-reality/augmented-reality.component';
import { SaveWorkEmailModalComponent } from '@shared/modals/save-work-email-modal/save-work-email-modal.component';
import { DisabledLinkModalComponent } from '@shared//modals/disabled-link-modal/disabled-link-modal.component';

import { DirectivesModule } from '@core/directives/directives.module';
import { TaskbarComponent } from '@shared/taskbar/taskbar.component';
import { ArNoBlindsModalComponent } from './modals/ar-no-blinds-modal/ar-no-blinds-modal.component';
import { AnotherDeviceModalComponent } from './modals/another-device-modal/another-device-modal.component';
import { LinkUnavailableModalComponent } from './modals/link-unavailable-modal/link-unavailable-modal.component';

@NgModule({
	declarations: [
		HeaderComponent,
		LoaderComponent,
		ErrorModalComponent,
		ToTopBtnComponent,
		LeaveModalComponent,
		DeleteModalComponent,
		RetailerSuccessModalComponent,
		RetailerNoSelectModalComponent,
		RetailerSelectModalComponent,
		SelectBackgroundModalComponent,
		SaveWorkModalComponent,
		RotateDeviceModalComponent,
		ArLoadingModalComponent,
		QuizModalComponent,
		RetailerInfoModalComponent,
		RetailerInfoPopoverComponent,
		AugmentedRealityComponent,
		SaveWorkEmailModalComponent,
		DisabledLinkModalComponent,
		TaskbarComponent,
		ArNoBlindsModalComponent,
		AnotherDeviceModalComponent,
		LinkUnavailableModalComponent,
	],
	imports: [CommonModule, AppRoutingModule, TranslateModule, SlickCarouselModule, ReactiveFormsModule, DirectivesModule],
	exports: [HeaderComponent, LoaderComponent, ToTopBtnComponent, RetailerInfoPopoverComponent, AugmentedRealityComponent],
})
export class SharedModule {}
