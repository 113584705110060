import { Component } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';

@Component({
	selector: 'app-control-model',
	templateUrl: './control-model.component.html',
	styleUrls: ['./control-model.component.scss'],
})
export class ControlModelComponent {
	active: boolean;

	constructor(private engineService: EngineService) {}

	onControl() {
		this.active = !this.active;
		this.engineService.setGizmoControl(this.active);
	}
}
