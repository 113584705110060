import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { PipesModule } from '@core/pipes/pipes.module';

@NgModule({
	imports: [CommonModule, MatDialogModule, PipesModule],
})
export class CoreModule {}
