import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { OverviewLinkHandlerService } from '@core/services/overview-link-handler/overview-link-handler.service';
import { Subscription } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LeaveModalComponent } from '@shared/modals/leave-modal/leave-modal.component';
import { CONFIG, STORAGE_NAMES, SELECTORS } from 'src/app/app.config';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { Location } from '@angular/common';
import { BlindProperties } from '@root/app.interfaces';

import * as _ from 'lodash';
import { ModalService } from '@core/services/modal/modal.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss', './header.responsive.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild('wrapper') wrapper: ElementRef;
	@ViewChild('navMenu') navMenu: ElementRef;

	getBlindName: Subscription;
	getSessionBlindsData: Subscription;
	getBlindNameStatus: Subscription;
	getBlindStatus: Subscription;
	getBlindEmpty: Subscription;
	getBlindType: Subscription;
	getActionBackPage: Subscription;
	getCheckExit: Subscription;
	getEmbeddedStatus: Subscription;
	isModalOpened = false;
	isEmbedded = false;
	isBlindTypeSelected: boolean;
	overviewStatus: boolean;

	constructor(
		private location: Location,
		private shareDataService: ShareService,
		private modalService: ModalService,
		private sessionStorageService: SessionStorageService,
		private changeDetection: ChangeDetectorRef,
		private overviewLinkHandlerService: OverviewLinkHandlerService,
		private countryCodeService: SetupPageService,
	) {}

	ngOnInit(): void {
		const blindDataStorage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		this.overviewStatus = _.isEmpty(blindDataStorage);

		this.getSessionBlindsData = this.shareDataService.getSessionBlindsData.subscribe(this.sessionBlindsHandler.bind(this));
		this.getBlindName = this.shareDataService.getBlindName.subscribe(this.hasBlinds.bind(this));
		this.getBlindNameStatus = this.shareDataService.getStatus.subscribe(this.blindStatusHandler.bind(this));
		this.getBlindEmpty = this.shareDataService.getBlindEmpty.subscribe(this.emptyBlindHandler.bind(this));

		this.getBlindType = this.shareDataService.getBlindType.subscribe((res: BlindProperties) => (this.isBlindTypeSelected = !!res.type));
		this.getCheckExit = this.shareDataService.getCheckExit.subscribe(this.checkExit.bind(this));
		this.getActionBackPage = this.shareDataService.getActionBackPage.subscribe((res) => {
			this.activeLinkHandler();
			this.setMainPageSize(res);
		});

		this.getEmbeddedStatus = this.shareDataService.getEmbeddedStatus.subscribe((res: boolean) => {
			this.isEmbedded = res;
		});

		this.checkExit();
	}

	ngOnDestroy(): void {
		this.getBlindName.unsubscribe();
		this.getSessionBlindsData.unsubscribe();
		this.getBlindStatus.unsubscribe();
		this.getBlindEmpty.unsubscribe();
		this.getActionBackPage.unsubscribe();
		this.getBlindType.unsubscribe();
		this.getCheckExit.unsubscribe();
		this.getEmbeddedStatus.unsubscribe();
	}

	hasBlinds(isBlindEmpty: string): void {
		this.overviewStatus = !isBlindEmpty.length;
		this.isBlindTypeSelected = !!isBlindEmpty;
		this.changeDetection.markForCheck();
	}

	blindStatusHandler(status: boolean): void {
		this.overviewStatus = !status;
		this.changeDetection.markForCheck();
	}

	sessionBlindsHandler(status: boolean): void {
		const currentDomainLink = this.location.path().split('?');
		const currentLink = currentDomainLink[0].split('/')[1] || 'home';

		if (status && currentLink === 'overview') {
			setTimeout(this.overviewLinkHandlerService.addActiveClass.bind(this));
		}

		this.overviewStatus = !status;
		this.isBlindTypeSelected = status;
		this.changeDetection.markForCheck();
	}

	emptyBlindHandler(status: boolean): void {
		if (!status) {
			this.activeLinkHandler();
		}

		this.overviewStatus = status;
		this.isBlindTypeSelected = !status;
		this.changeDetection.markForCheck();
	}

	activeLinkHandler(): void {
		if (this.navMenu) {
			const navigationList = this.navMenu.nativeElement.querySelectorAll('li');
			const lastElement = Array.from(navigationList).pop() as Element;

			lastElement?.classList.remove('active');
			this.shareDataService.setTaskbarPanel(true);
		}
	}

	setMainPageSize(status: boolean): void {
		const mainPageWrapper = document.querySelector(SELECTORS.wrapper) as HTMLElement;

		if (status && window.innerWidth > CONFIG.breakpoints['l-desktop']) {
			const toolBarControls = document.querySelector(SELECTORS.tool_bar_controls) as HTMLElement;
			const header = this.wrapper.nativeElement.parentElement;

			setTimeout(() => {
				const headerHeight = header.offsetHeight;

				mainPageWrapper.style.height = `calc(100vh - ${headerHeight}px)`;
				toolBarControls.style.height = `calc(100vh - ${headerHeight + 40}px)`;

				window.dispatchEvent(new Event('resize'));
			}, 400);
		}

		this.changeDetection.markForCheck();
	}

	overviewLinkHandler(status: boolean): void {
		if (!status) {
			this.overviewLinkHandlerService.link();
		}
	}

	openModal(e: Event): void {
		e.preventDefault();
		const isProgressSaved = this.getProgressSaved();
		let dialogRef: MatDialogRef<any>;

		if (isProgressSaved) {
			dialogRef = this.modalService.openResponsiveDialog<{ embedded: boolean }>({
				component: LeaveModalComponent,
				data: { embedded: this.isEmbedded },
			});
			this.isModalOpened = true;
		} else {
			this.countryCodeService.resetOnBeforeUnloadHandler();
			this.countryCodeService.parseLocalLinkHandler();
		}

		if (dialogRef) {
			dialogRef.afterClosed().subscribe(() => {
				this.isModalOpened = false;
			});
		}
	}

	checkExit(): void {
		window.onbeforeunload = () => {
			return this.getProgressSaved() && !this.isModalOpened;
		};
	}

	getProgressSaved() {
		const storage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const isProgressSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_progress_saved);

		return !_.isEmpty(storage) && !isProgressSaved;
	}
}
