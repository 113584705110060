import { Injectable } from '@angular/core';
import { Logger } from '@babylonjs/core/Misc/logger';
import { Camera, Constants, Engine, FxaaPostProcess, IScreenshotSize, Nullable, RenderTargetTexture, Texture } from '@babylonjs/core';

@Injectable({
	providedIn: 'root',
})
export class ScreenshotBabylonService {
	screenshotCanvas: HTMLCanvasElement;

	constructor() {}

	CreateScreenshotUsingRenderTargetAsync(
		engine: Engine,
		camera: Camera,
		size: number | IScreenshotSize,
		mimeType: string = 'image/png',
		samples: number = 1,
		antialiasing: boolean = false,
		fileName?: string,
		renderSprites: boolean = false,
	): Promise<string> {
		return new Promise((resolve, reject) => {
			this.CreateScreenshotUsingRenderTarget(
				engine,
				camera,
				size,
				(data) => {
					if (typeof data !== 'undefined') {
						resolve(data);
					} else {
						reject(new Error('Data is undefined'));
					}
				},
				mimeType,
				samples,
				antialiasing,
				fileName,
				renderSprites,
			);
		});
	}

	CreateScreenshotUsingRenderTarget(
		engine: Engine,
		camera: Camera,
		size: IScreenshotSize | number,
		successCallback?: (data: string) => void,
		mimeType: string = 'image/png',
		samples: number = 1,
		antialiasing: boolean = false,
		fileName?: string,
		renderSprites: boolean = false,
		enableStencilBuffer: boolean = false,
	): void {
		const { height, width } = this._getScreenshotSize(engine, camera, size);
		const targetTextureSize = { width, height };

		if (!(height && width)) {
			Logger.Error(`Invalid 'size' parameter !`);
			return;
		}
		const renderCanvas = engine.getRenderingCanvas();
		if (!renderCanvas) {
			Logger.Error('No rendering canvas found !');
			return;
		}

		const originalSize = { width: renderCanvas.width, height: renderCanvas.height };
		engine.setSize(width, height);

		const scene = camera.getScene();

		let previousCamera: Nullable<Camera> = null;
		const previousCameras = scene.activeCameras;
		if (scene.activeCamera !== camera || (scene.activeCameras && scene.activeCameras.length)) {
			previousCamera = scene.activeCamera;
			scene.activeCamera = camera;
		}

		scene.render();

		// At this point size can be a number, or an object (according to engine.prototype.createRenderTargetTexture method)
		const texture = new RenderTargetTexture(
			'screenShot',
			targetTextureSize,
			scene,
			false,
			false,
			Constants.TEXTURETYPE_UNSIGNED_INT,
			false,
			Texture.NEAREST_SAMPLINGMODE,
			undefined,
			enableStencilBuffer,
		);
		texture.renderList = null;
		texture.samples = samples;
		texture.renderSprites = renderSprites;
		texture.onAfterRenderObservable.add(() => {
			this.DumpFramebuffer(width, height, engine, successCallback, mimeType);
		});

		const renderToTexture = () => {
			scene.incrementRenderId();
			scene.resetCachedMaterial();
			texture.render(true);
			texture.dispose();

			if (previousCamera) {
				scene.activeCamera = previousCamera;
			}
			scene.activeCameras = previousCameras;

			engine.setSize(originalSize.width, originalSize.height);
			camera.getProjectionMatrix(true); // Force cache refresh;
		};

		if (antialiasing) {
			const fxaaPostProcess = new FxaaPostProcess('antialiasing', 1.0, scene.activeCamera);
			texture.addPostProcess(fxaaPostProcess);
			// Async Shader Compilation can lead to none ready effects in synchronous code
			if (!fxaaPostProcess.getEffect().isReady()) {
				fxaaPostProcess.getEffect().onCompiled = () => {
					renderToTexture();
				};
			}
			// The effect is ready we can render
			else {
				renderToTexture();
			}
		} else {
			// No need to wait for extra resources to be ready
			renderToTexture();
		}
	}

	_getScreenshotSize(engine: Engine, camera: Camera, size: IScreenshotSize | number): { height: number; width: number } {
		let height = 0;
		let width = 0;

		// If a size value defined as object
		if (typeof size === 'object') {
			const precision = size.precision
				? Math.abs(size.precision) // prevent GL_INVALID_VALUE : glViewport: negative width/height
				: 1;

			// If a width and height values is specified
			if (size.width && size.height) {
				height = size.height * precision;
				width = size.width * precision;
			}
			// If passing only width, computing height to keep display canvas ratio.
			else if (size.width && !size.height) {
				width = size.width * precision;
				height = Math.round(width / engine.getAspectRatio(camera));
			}
			// If passing only height, computing width to keep display canvas ratio.
			else if (size.height && !size.width) {
				height = size.height * precision;
				width = Math.round(height * engine.getAspectRatio(camera));
			} else {
				width = Math.round(engine.getRenderWidth() * precision);
				height = Math.round(width / engine.getAspectRatio(camera));
			}
		}
		// Assuming here that "size" parameter is a number
		else if (!isNaN(size)) {
			height = size;
			width = size;
		}

		// When creating the image data from the CanvasRenderingContext2D, the width and height is clamped to the size of the _gl context
		// On certain GPUs, it seems as if the _gl context truncates to an integer automatically.
		// Therefore, if a user tries to pass the width of their canvas element.
		// and it happens to be a float (1000.5 x 600.5 px), the engine.readPixels will return a different size array than context.createImageData
		// to resolve this, we truncate the floats here to ensure the same size
		if (width) {
			width = Math.floor(width);
		}
		if (height) {
			height = Math.floor(height);
		}

		return { height: height | 0, width: width | 0 };
	}

	async DumpFramebuffer(
		width: number,
		height: number,
		engine: Engine,
		successCallback?: (data: string) => void,
		mimeType: string = 'image/png',
	): Promise<void> {
		// Read the contents of the framebuffer
		const numberOfChannelsByLine = width * 4;
		const halfHeight = height / 2;

		// Reading data from WebGL
		const data = await engine.readPixels(0, 0, width, height);

		// To flip image on Y axis.
		for (let i = 0; i < halfHeight; i++) {
			for (let j = 0; j < numberOfChannelsByLine; j++) {
				const currentCell = j + i * numberOfChannelsByLine;
				const targetLine = height - i - 1;
				const targetCell = j + targetLine * numberOfChannelsByLine;

				const temp = data[currentCell];
				data[currentCell] = data[targetCell];
				data[targetCell] = temp;
			}
		}

		// Create a 2D canvas to store the result
		if (!this.screenshotCanvas) {
			this.screenshotCanvas = document.createElement('canvas');
		}
		this.screenshotCanvas.width = width;
		this.screenshotCanvas.height = height;
		const context = this.screenshotCanvas.getContext('2d');

		if (context) {
			// Copy the pixels to a 2D canvas
			const imageData = context.createImageData(width, height);
			const castData: any = imageData.data;
			castData.set(data);
			context.putImageData(imageData, 0, 0);

			const base64Image = this.screenshotCanvas.toDataURL(mimeType);
			successCallback(base64Image);
		}
	}
}
