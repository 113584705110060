<div #overviewPage class="mc-overview-wrap">
	<app-summary-table class="mc-overview-section" [ngClass]="{ 'last-type': !addInfoAvailability }"></app-summary-table>
	<div #retailerInfo *ngIf="addInfoAvailability" class="mc-overview-section last-type">
		<app-retailar-action (toggleInfoAvailability)="setInfoAvailability($event)"></app-retailar-action>
	</div>
	<app-to-top-btn></app-to-top-btn>
	<div class="mc-overview-copy">
		<a class="mc-overview-copy-link" href="https://www.ziptrak.com.au/contact/" target="_blank">
			{{ 'overview.contact' | translate }}
		</a>
		<a class="mc-overview-copy-link" href="https://www.ziptrak.com.au/contact/privacy-policy/" target="_blank">
			{{ 'overview.privacy_policy' | translate }}
		</a>

		<div class="mc-overview-copy-block">
			<span class="mc-overview-copy-text">{{ 'overview.copyright' | translate: { date: getCurrentYear() } }}</span>
			<a class="mc-overview-copy-link" href="https://www.ziptrak.com.au/contact/terms-and-conditions/" target="_blank">{{
				'overview.terms' | translate
			}}</a>
		</div>
	</div>
</div>
