import { ChangeDetectorRef, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { OverviewLinkHandlerService } from '@core/services/overview-link-handler/overview-link-handler.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { STORAGE_NAMES } from '@root/app.config';
import { BlindData, BlindProperties } from '@root/app.interfaces';

import * as _ from 'lodash';
import { first, delay } from 'rxjs/operators';

@Component({
	selector: 'app-add-blind',
	templateUrl: './add-blind.component.html',
	styleUrls: ['./add-blind.component.scss', './add-blind.responsive.scss'],
})
export class AddBlindComponent implements OnInit, OnDestroy {
	@Input() isToolBarCollapsed: boolean;

	getBlindName: Subscription;

	data: BlindData[] = [];
	blindId: number;
	setBlindInfoStatus = false;
	isModelLoaded: string;
	overviewStatus: boolean;
	getBlindInfoStatus: Subscription;
	getBlindEmpty: Subscription;
	getModelLoaded: Subscription;
	getBlindNameStatus: Subscription;
	getBlindStatus: Subscription;
	getSceneCreated: Subscription;
	getBlindType: Subscription;
	getAddBlindEvent: Subscription;
	getScreenshotCreated: Subscription;

	constructor(
		private shareDataService: ShareService,
		private screenShotService: ScreenshotService,
		private overviewLinkHandlerService: OverviewLinkHandlerService,
		private setupPageService: SetupPageService,
		private changeDetection: ChangeDetectorRef,
		private sessionStorageService: SessionStorageService,
		private engineService: EngineService,
	) {}

	ngOnInit(): void {
		const blindDataStorage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		this.getModelLoaded = this.shareDataService.getModelLoaded.subscribe((res: boolean) => {
			this.setBlindInfoStatus = res;
			this.changeDetection.markForCheck();
		});
		this.getBlindInfoStatus = this.shareDataService.getStatus.subscribe((res: boolean) => {
			this.setBlindInfoStatus = res;
			this.changeDetection.markForCheck();
		});
		this.getBlindStatus = this.shareDataService.getEmptyBlindData.subscribe((res: boolean) => {
			this.overviewStatus = res;
			this.changeDetection.markForCheck();
		});
		this.getSceneCreated = this.shareDataService.getSceneCreated.subscribe((res: string) => {
			this.isModelLoaded = res;
		});
		this.getBlindType = this.shareDataService.getBlindType.subscribe(this.onSelectOption.bind(this));

		this.overviewStatus = _.isEmpty(blindDataStorage);
		this.getBlindName = this.shareDataService.getBlindName.subscribe(this.hasBlinds.bind(this));
		this.getBlindEmpty = this.shareDataService.getBlindEmpty.subscribe(this.hasBlinds.bind(this));
		this.getBlindNameStatus = this.shareDataService.getStatus.subscribe(this.blindStatusHandler.bind(this));
		this.getAddBlindEvent = this.shareDataService.getAddBlind.subscribe(this.onAddBlindHandler.bind(this));
	}

	ngOnDestroy(): void {
		this.getModelLoaded.unsubscribe();
		this.getBlindInfoStatus.unsubscribe();
		this.getBlindName.unsubscribe();
		this.getBlindEmpty.unsubscribe();
		this.getBlindNameStatus.unsubscribe();
		this.getBlindStatus.unsubscribe();
		this.getBlindType.unsubscribe();
		this.getSceneCreated.unsubscribe();
		this.getAddBlindEvent.unsubscribe();
		this.getScreenshotCreated?.unsubscribe();
	}

	hasBlinds(isBlindEmpty: boolean | string): void {
		const productType = this.setupPageService.getProductType();
		this.overviewStatus = _.isBoolean(isBlindEmpty) && isBlindEmpty;

		if (this.overviewStatus && productType === 'all') {
			this.setupPageService.sendBlindTypeForRender(true);
		}

		this.changeDetection.markForCheck();
	}

	blindStatusHandler(status: boolean): void {
		const blindDataStorage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);

		if (!_.isEmpty(blindDataStorage)) {
			this.overviewStatus = !status;
		}

		this.changeDetection.markForCheck();
	}

	onAddBlindHandler(saveScreenShot = true): void {
		const productType = this.setupPageService.getProductType();

		if (productType === 'all') {
			this.setupPageService
				.sendBlindTypeForRender(false, saveScreenShot ? this.shareDataService.getScreenShotBLindTemp : null)
				?.afterOpened()
				.subscribe(() => {
					if (saveScreenShot) {
						this.saveCurrentBlindScreenshot();
					}
				});
		} else {
			const id = Math.floor(1000 + Math.random() * 9000);
			const onScreenshotCreated = () => {
				this.engineService.createScene(productType, id.toString());
				this.sessionStorageService.setSession(productType, STORAGE_NAMES.zip_blind_type);
				this.onSelectOption({ type: productType, id });
			};

			if (saveScreenShot && !this.overviewStatus) {
				this.getScreenshotCreated = this.shareDataService.getScreenShotBLindTemp
					.pipe(first(), delay(20))
					.subscribe(onScreenshotCreated.bind(this, this.getScreenshotCreated));
				this.saveCurrentBlindScreenshot();
			} else {
				setTimeout(onScreenshotCreated.bind(this));
			}
		}
	}

	onSelectOption(data: BlindProperties): void {
		const hasSessionData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const defaultBlindName = data.type === 'outdoor' ? 'Ziptrak® Outdoor Blind' : 'Ziptrak® Interior Blind';

		this.blindId = data.id;

		const defaultBlindTemp = [
			{
				default_name: defaultBlindName,
				blind_id: this.blindId,
				type: data.type,
			},
		];

		if (_.isEmpty(hasSessionData)) {
			this.data = defaultBlindTemp;
		} else {
			this.data = hasSessionData.concat(defaultBlindTemp);
		}

		this.sessionStorageService.setBlindData(this.data, STORAGE_NAMES.zip_blind_data);
		this.sessionStorageService.setBlindData(this.blindId, STORAGE_NAMES.zip_current_blind_id);
		this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		this.shareDataService.changeData(this.data);
		this.shareDataService.setBlindSelect({ id: this.blindId, status: 'new' });
		this.shareDataService.setScreenShotStatus(this.engineService.isImageVisualisation);
	}

	overviewLinkHandler(): void {
		this.overviewLinkHandlerService.link().then();
	}

	saveCurrentBlindScreenshot(): void {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const lastOpenedBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		const hasSessionData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);

		if (!_.isEmpty(hasSessionData)) {
			this.screenShotService.saveScreenShot(currentBlindId || lastOpenedBlindId, true);
		}
	}
}
