import { Directive, forwardRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[appValidateEmail]',
	providers: [
		{
			provide: NG_ASYNC_VALIDATORS,
			useExisting: forwardRef(() => InputEmailValidator),
			multi: true,
		},
	],
})
export class InputEmailValidator implements AsyncValidator {
	constructor() {}

	validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
		const regex = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$');
		const valid = regex.test(control.value.trim());

		if (!valid) {
			return of({ email: true });
		}

		return of(null);
	}
}
