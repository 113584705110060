import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResizeDirective } from '@core/directives/domElems/resize.directive';

@NgModule({
	declarations: [ResizeDirective],
	imports: [CommonModule],
	exports: [ResizeDirective],
})
export class DirectivesModule {}
