import {
	Component,
	OnDestroy,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
	HostListener,
	AfterViewInit,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ActivatedRoute } from '@angular/router';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AccordionSelector, BlindData, Breakpoints } from '@root/app.interfaces';
import { STORAGE_NAMES, SELECTORS, VIEW_TYPES, BREAKPOINTS, CONFIG, CLASSES } from '@root/app.config';
import { PassDataService } from '@core/services/pass-data/pass-data.service';

declare const $: any;
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { ScreenshotToBlindService } from '@core/services/screenshot-to-blind/screenshot-to-blind.service';

@Component({
	selector: 'app-blind-list',
	templateUrl: './blind-list.component.html',
	styleUrls: ['./blind-list.component.scss', './blind-list.responsive.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlindListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	@Input() isToolBarCollapsed: boolean;
	@Input() isModelLoaded: string;

	@ViewChild('newBlindName') newBlindName: ElementRef;
	@ViewChild('blindListContainer') blindListContainer: ElementRef<HTMLElement>;

	@ViewChild('copyPopover') copyPopover: NgbPopover;
	@ViewChild('deletePopover') deletePopover: NgbPopover;

	isMobile: boolean;
	blindsData: BlindData[];
	copiedBlind: BlindData;
	copiedBlindIndex: number;
	accordShow: boolean;
	item = {
		length: 0,
		id: 0,
	};
	slideSpeed = 200;
	isBlindName = true;
	isFirstLoading = true;
	blindTempName: string;
	sessionText = {
		material_title: '',
		operation_title: '',
		mounting_title: '',
		size_pop_up_text: '',
	};
	blindSelectors: AccordionSelector = {
		title: '.mc-blind-item',
		content: '.mc-blind-item__setup-container',
		item: '.mc-blind-item',
	};
	blindId: number;
	blindItemObject = {};
	blindDisplayStatuses = {};
	blindType = this.sessionStorageService.getSession(STORAGE_NAMES.zip_blind_type);
	selectedBlindId: number;
	isDesignType: boolean;
	isBlindSelected = true;
	setBlindSelectData = {
		id: 0,
		type: '',
	};

	breakpoints: Breakpoints = BREAKPOINTS;
	swipeCoordY: number;
	startBlindsListScrolled: boolean;

	viewTypeChanged = false;
	viewType: string;

	getBlindsData: Subscription;
	getBlindStatus: Subscription;
	getSessionBlindsData: Subscription;
	getActivatedRouteRules: Subscription;
	getScreenShot: Subscription;
	getScreenShotStatus: Subscription;
	getModelLoadedStatus: Subscription;
	getBlindsUpdated: Subscription;
	getModelDisplay: Subscription;
	getViewType: Subscription;
	getSelectedModel: Subscription;
	getAccordionMobileBlindList: Subscription;
	getTaskbarControls: Subscription;

	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange() {
		const isLandscape = screen.orientation.type.includes('landscape');
		const width = [window.innerWidth, window.innerHeight].sort((a, b) => a - b)[+isLandscape];

		if (this.isWidthLessThanTabletLandscape(width)) {
			setTimeout(this.callBlindListSwipeAnimation.bind(this, this.mobileBlindListStatus(), false, 0));
		}
	}

	mobileBlindListStatus(): boolean {
		return this.sessionStorageService.getSession(STORAGE_NAMES.zip_mobile_menu_status);
	}

	activeBlindItem(id: number): void {
		this.blindId = id;

		this.collapseAccordionList();
		if (this.mobileBlindListStatus() || window.innerWidth > CONFIG.breakpoints['tablet-landscape']) {
			this.driveAccordionItem(id, true);
		}

		this.blindNameSave(id);

		if (this.isWidthLessThanTabletLandscape() && !this.mobileBlindListStatus()) {
			this.scrollToCurrentBlind();
		}
	}

	constructor(
		public shareDataService: ShareService,
		public engineService: EngineService,
		public serverDataService: ServerDataService,
		public saveSessionBlind: SaveSessionBlindsService,
		public route: ActivatedRoute,
		public screenShotService: ScreenshotService,
		public screenshotToBlindService: ScreenshotToBlindService,
		public sessionStorageService: SessionStorageService,
		public passDataService: PassDataService,
		public changeDetection: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.isMobile = this.passDataService.isMobileOrTablet;
		this.isDesignType = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;

		this.getBlindsData = this.shareDataService.currentData.subscribe(this.setBlindDataHandler.bind(this));
		this.getScreenShot = this.screenShotService.getScreenShot.subscribe((res) => {
			this.isScreenShotLoadedOnServer = this.screenshotToBlindService.setScreenshotToBlind(res, '', this.blindsData);

			this.changeDetection.markForCheck();
		});
		this.getSelectedModel = this.shareDataService.getSelectedModel.subscribe(this.onBlindSelectedFromScene.bind(this));
		this.getScreenShotStatus = this.shareDataService.getScreenShotStatus.subscribe((res: boolean) => {
			this.screenShotService.screenShotNewBlindStatus = res;
		});

		this.setTaskbarHandler();
		this.getServerDataHandler();
		this.blindStatusHandler();
		this.activatedRouteRulesHandler();
		this.blindsUpdateHandler();
		this.modelLoadedHandler();
		this.modelDisplayHandler();
		this.viewTypeHandler();
		this.onAccordionMobileBlindListHandler();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.isToolBarCollapsed?.currentValue) {
			this.collapseAccordionList();
		}
	}

	ngOnDestroy(): void {
		this.getBlindsData.unsubscribe();
		this.getBlindStatus.unsubscribe();
		this.getBlindsUpdated.unsubscribe();
		this.getActivatedRouteRules.unsubscribe();
		this.getScreenShot.unsubscribe();
		this.getScreenShotStatus.unsubscribe();
		this.getModelLoadedStatus.unsubscribe();
		this.getSessionBlindsData?.unsubscribe();
		this.getViewType.unsubscribe();
		this.getSelectedModel.unsubscribe();
		this.getModelDisplay.unsubscribe();
		this.getAccordionMobileBlindList.unsubscribe();
		this.getTaskbarControls.unsubscribe();
	}

	ngAfterViewInit() {
		this.selectedBlindId ||= this.getBlindIdFromStorage();
	}

	setTaskbarHandler(): void {
		this.getTaskbarControls = this.shareDataService.getTaskbarControls.subscribe((res) => {
			if (window.innerWidth <= CONFIG.breakpoints['tablet-landscape']) {
				this.callToolBarHideAnimation(res);
				this.callBlindListSwipeAnimation(res ? false : null);
			} else {
				this.callToolBarHideAnimation(false);
			}
		});
	}

	getServerDataHandler(): void {
		this.getSessionBlindsData = this.shareDataService.getSessionBlindsData.subscribe((res) => {
			this.setConfigurationDataHandler();

			if (!res && this.isModelLoaded.length) {
				this.engineService.createScene(this.isModelLoaded);
			}

			this.changeDetection.markForCheck();
		});

		this.setConfigurationDataHandler();
	}

	setConfigurationDataHandler(): void {
		this.getSessionDefaultValue();
		this.getSessionStorageData();

		if (this.blindsData) {
			setTimeout(this.accordionCurrentBlind.bind(this));
		}

		setTimeout(this.setNameMask.bind(this));
	}

	setSessionStorage(): void {
		const sessionStorageSession = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session);

		if (sessionStorageSession) {
			this.shareDataService.setLinkSessionKey(sessionStorageSession);
		}
	}

	getSessionStorageData(): void {
		const storageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);

		if (_.isEmpty(this.blindsData)) {
			this.blindsData = storageData;
		} else {
			storageData.forEach((blind, index) => {
				this.blindsData[index] = Object.assign(this.blindsData[index] || {}, blind);
			});
		}
	}

	setBlindDataHandler(data: BlindData[]): void {
		for (const item of data) {
			this.item = { length: data.length, id: item.blind_id };
			this.setBlindSelectData = { id: item.blind_id, type: item.type };
		}
		setTimeout(() => {
			this.setNameMask();
			this.activeBlindItem(this.item.id);
			this.sessionStorageService.setBlindData(true, STORAGE_NAMES.zip_model_init);
		});

		this.blindTempName = '';
		this.getSessionStorageData();

		if (this.blindsData.length > 0) {
			this.shareDataService.setStatus(false);
		}
		this.blindItemObject = {};
		this.changeDetection.markForCheck();
	}

	blindName(newBlindName, event, id): void {
		this.blindTempName = newBlindName.trim();

		if (event.keyCode === 13 || event.type === 'blur') {
			this.blindNameSave(id);
		}
	}

	setNameMask(): void {
		const blindList = $(SELECTORS.blind_list);

		if (!_.isEmpty(this.blindsData)) {
			for (const item of this.blindsData) {
				if (item.has_name) {
					for (const blindId of blindList) {
						const currentBlindId = $(blindId).find(`#${item.blind_id}`);
						currentBlindId.find(SELECTORS.config_item_setup).addClass('named');
					}
				}
			}
		}
	}

	blindNameSave(id): void {
		const blindList = $(SELECTORS.blind_list);

		for (const blindId of blindList) {
			const currentBlindId = $(blindId).find(`#${id}`);

			if (this.newBlindName?.nativeElement) {
				this.blindTempName = $(this.newBlindName.nativeElement).val();
			}

			if (this.blindTempName?.length > 1) {
				currentBlindId.find(SELECTORS.config_item_setup).removeClass('error');
				currentBlindId.find(SELECTORS.config_item_setup).addClass('named');

				this.getBlindData(id, true);
				this.shareDataService.setStatus(true);
			} else {
				currentBlindId.find(SELECTORS.config_item_setup).addClass('error');
			}
		}
	}

	blindNameEdit(id, event): void {
		const elem = $(event.currentTarget);
		$(event.currentTarget).closest(SELECTORS.config_item).removeClass('named');
		$(event.currentTarget).closest(SELECTORS.config_item).removeClass('error');
		setTimeout(() => elem.children().focus());

		this.getBlindData(id, false);
		this.shareDataService.setStatus(false);
	}

	getBlindData(id, hasName): void {
		const blindItem = this.blindsData.filter((x) => x.blind_id === id)[0];
		const sessionStorageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const blindItemCopy = sessionStorageData.filter((x) => x.blind_id === id)[0];

		blindItem.setup = blindItemCopy.setup;

		if (this.blindTempName?.length && (_.isEmpty(blindItem.name) || !blindItem.has_name)) {
			blindItem.name = this.blindTempName;
		} else {
			this.blindTempName = blindItem.name;
		}

		blindItem.has_name = hasName;

		sessionStorageData[sessionStorageData.indexOf(blindItemCopy)] = blindItem;

		this.saveBlindData(sessionStorageData);

		if (this.isDesignType) {
			this.shareDataService.setBlindName(this.blindTempName);
		}
	}

	accordionConfAction(event: Event, selector: string, status: boolean): void {
		const accordionList = $(selector);
		const accordionItem = $(event.currentTarget).parents(selector);

		for (const item of accordionList) {
			if ($(item).hasClass('active')) {
				$(item)
					.children(0)
					.next()
					.stop()
					.slideUp(this.slideSpeed, () => {
						$(item).removeClass('active');
					});
			}
		}

		this.accordShow = status;
		this.accordShow = !this.accordShow;

		if (accordionItem.hasClass('active')) {
			$(event.currentTarget)
				.next()
				.stop()
				.slideUp(this.slideSpeed, () => {
					this.accordShow = false;
					accordionItem.removeClass('active');
				});
		} else {
			accordionItem.addClass('active');
			$(event.currentTarget)
				.next()
				.stop()
				.height('auto')
				.slideDown(this.slideSpeed, () => {
					this.accordShow = true;
				});
		}
	}

	collapseAccordionList(): void {
		const accordionList = this.blindListContainer?.nativeElement.children || [];
		[...accordionList]?.forEach((item: HTMLElement) => {
			if (item.id) {
				item.dataset.slided = 'false';
				item.classList.remove('active');
				item.querySelector(this.blindSelectors.content).classList.add('hidden');
			}
		});
	}

	driveAccordionItem(id: number, open: boolean): void {
		const accordionList = this.blindListContainer?.nativeElement.children || [];
		[...accordionList]?.forEach((item: HTMLElement) => {
			if (item.id === id.toString() && !this.isToolBarCollapsed) {
				item.classList.toggle('active', open);
				item.dataset.slided = open.toString();
				item.querySelector(this.blindSelectors.content).classList.toggle('hidden', !open);
			}
		});

		if (open && id === this.selectedBlindId) {
			this.showCurrentBlindOnChangeView();
		}

		this.selectedBlindId = id;
		setTimeout(this.scrollToCurrentBlind.bind(this), 100);
	}

	accordionBlindItems(event: Event, itemId: number, type: string): void {
		if (this.isBlindName) {
			let slided = false;
			let active = false;

			if (event) {
				const element = event.currentTarget as HTMLElement;
				const accordionItem = element.parentElement;

				slided = accordionItem.dataset.slided === 'true';
				active = accordionItem.classList.contains('active');
			}

			if ((event || this.mobileBlindListStatus()) && this.isWidthLessThanTabletLandscape()) {
				this.callBlindListSwipeAnimation(true);
			}

			if (event && (slided || active)) {
				this.driveAccordionItem(itemId, false);
				this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_current_blind_id);
				this.sessionStorageService.setBlindData(itemId, STORAGE_NAMES.zip_last_opened_blind_id);
			} else {
				const screenshotPromise = new Promise((resolve) => {
					if (!this.isFirstLoading) {
						this.shareDataService.getScreenShotBLindTemp.pipe(first()).subscribe(() => resolve(true));
						this.shareDataService.getScreenShotAborted.pipe(first()).subscribe(() => {
							this.screenShotService.screenShotStatus = false;
							resolve(true);
						});

						this.saveCurrentBlindScreenshot();
					} else {
						resolve(true);
					}
				});
				this.sessionStorageService.setBlindData(itemId, STORAGE_NAMES.zip_current_blind_id);
				this.sessionStorageService.removeBlindData(STORAGE_NAMES.zip_last_opened_blind_id);

				screenshotPromise.then(() => {
					if (this.isModelLoaded !== type) {
						this.engineService.createScene(type, itemId.toString());
						this.sessionStorageService.setSession(type, STORAGE_NAMES.zip_blind_type);
					} else {
						this.sessionStorageService.setSession(type, STORAGE_NAMES.zip_blind_type);
						this.shareDataService.setAccordionType(type);
						this.engineService.isCopyingModel = false;
					}

					this.updateBlindsDataOnCopy(!this.isDesignType, true);

					this.collapseAccordionList();
					setTimeout(this.driveAccordionItem.bind(this, itemId, true));
					this.shareDataService.setCurrentBlindByType(type);

					this.sessionStorageService
						.getBlindData(STORAGE_NAMES.zip_blind_data)
						.filter((x) => x.blind_id === itemId)
						.map((item: BlindData) => {
							this.setBlindSelectData = {
								id: item.blind_id,
								type: item.type,
							};

							if (item.has_name) {
								this.shareDataService.setBlindName(item.name);

								if (this.isModelLoaded === type) {
									this.engineService.onChangeBlindId(this.setBlindSelectData.id.toString());
									this.shareDataService.setBlindSelect(this.setBlindSelectData);
								}

								this.isBlindSelected = this.isModelLoaded === type;
							}
						});

					if (this.mobileBlindListStatus() && this.isWidthLessThanTabletLandscape()) {
						setTimeout(this.scrollToCurrentBlind.bind(this, 'smooth'));
					}
				});
			}
		}
	}

	deleteBlindItem(itemId: number): void {
		const sessionSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		this.getSessionStorageData();

		const deletedBlindIndex = this.blindsData.findIndex((x) => x.blind_id === itemId);
		if (deletedBlindIndex < this.blindsData.length - 1) {
			this.blindsData.forEach((x, i) => (x.name = i > deletedBlindIndex && x.name === `Blind ${i + 1}` ? `Blind ${i}` : x.name));
		}

		this.blindsData.splice(deletedBlindIndex, 1);
		this.deleteModel(itemId.toString(), !this.blindsData.length);
		this.saveBlindData();

		if (this.blindsData.length > 0) {
			this.engineService.setModelDefaultPosition();
			this.shareDataService.setBlindUnselect(true);
			this.accordionCurrentBlind(true);
			this.scrollToCurrentBlind();
		} else if (!sessionSaved) {
			this.deletedLastBlindItem();
		} else {
			document.querySelector(SELECTORS.tool_bar_accordion).classList.add(CLASSES.loading);
		}

		this.shareDataService.setStatus(true);
		this.blindItemObject = {};
	}

	getSessionDefaultValue(): void {
		const sessionStorageText = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_text);

		if (!_.isEmpty(sessionStorageText)) {
			this.sessionText = sessionStorageText;
		}
	}

	saveCurrentBlindScreenshot(): void {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		this.screenShotService.saveScreenShot(currentBlindId, false);
	}

	accordionCurrentBlind(afterDelete?: boolean): void {
		if (!_.isEmpty(this.blindsData) && this.isModelLoaded && !this.isToolBarCollapsed) {
			const lastOpenedBlindId = this.getBlindIdFromStorage();
			const lastArrayBlind = this.blindsData[this.blindsData.length - 1];
			const lastOpenedBlind =
				this.sessionStorageService.getBlindItemById(lastOpenedBlindId, STORAGE_NAMES.zip_blind_data)[0] || lastArrayBlind;
			const lastOpenedBlindElement = $(`#${lastOpenedBlind.blind_id}`);

			if (!lastOpenedBlindElement[0]?.classList.contains('active') || afterDelete) {
				this.accordionBlindItems(null, lastOpenedBlind.blind_id, lastOpenedBlind.type);
			}
		}
	}

	onBlindItemDescriptionHandler(event: Event, id?: number) {
		const currentBlind = this.blindsData.filter((item) => item.blind_id === id)[0];
		this.blindItemObject = { ...currentBlind.setup, ...event };
		currentBlind.setup = this.blindItemObject;
	}

	deletedLastBlindItem(): void {
		this.shareDataService.setBlindEmpty(true);
		this.engineService.setModelDefaultPosition();
		this.engineService.setGizmoScaleControlVisibleDYB();
	}

	onPopover(popover: NgbPopover, button: HTMLElement): void {
		$(button).toggleClass('active', popover.isOpen());
	}

	saveBlindData(data: any = this.blindsData): void {
		this.sessionStorageService.setBlindData(data, STORAGE_NAMES.zip_blind_data);
		this.saveSessionBlind.PutStorageDataToServer();
	}

	onTouchEvent(event: TouchEvent): void {
		const blindList = this.blindListContainer?.nativeElement as HTMLElement;
		const toolBar = document.querySelector(SELECTORS.tool_bar) as HTMLElement;
		const isMobileSliderTouch = (event.target as HTMLElement).closest(SELECTORS.mobile_slider);
		const isBlindItemTouch = (event.target as HTMLElement).closest(SELECTORS.blind_item);
		const isBlindListTouch = (event.target as HTMLElement).closest(SELECTORS.blind_list);
		const isBlindsListHasScroll = blindList.scrollHeight - 10 > blindList.clientHeight;
		const isBlindsListScrolled = blindList.scrollTop !== 0;
		const isDesktopScreen = window.innerWidth > CONFIG.breakpoints['tablet-landscape'];

		if ((!this.mobileBlindListStatus() && isBlindsListScrolled && isBlindListTouch) || isDesktopScreen) {
			return;
		}

		const maxOpenHeight = 120;
		const minCloseHeight = window.innerHeight - 200;
		const minHideBottom = -20;
		const coordY = event.changedTouches[0].clientY;
		const direction = coordY - this.swipeCoordY;

		if (event.type === 'touchstart') {
			this.swipeCoordY = coordY;
			this.startBlindsListScrolled = this.mobileBlindListStatus() && isBlindsListScrolled;
		} else if (event.type === 'touchmove') {
			let deltaY = this.swipeCoordY - coordY;

			if (
				(isBlindListTouch && !isBlindsListHasScroll) ||
				(!isBlindsListScrolled && direction >= 0) ||
				isMobileSliderTouch ||
				!this.mobileBlindListStatus()
			) {
				event.preventDefault();
			}

			if (!this.startBlindsListScrolled && this.mobileBlindListStatus() && isBlindsListHasScroll && deltaY > 0) {
				this.startBlindsListScrolled = true;
			} else if (this.startBlindsListScrolled) {
				deltaY = 0;
			}

			const toolBarBottom = window.innerHeight - toolBar.getBoundingClientRect().bottom;
			const isSwipeDown = toolBarBottom > -100 && toolBarBottom <= 0 && deltaY < -20;
			if (blindList.offsetHeight < 70 && isSwipeDown) {
				const newToolBarBottom = toolBarBottom - direction;
				toolBar.style.bottom = newToolBarBottom + 'px';

				const hideStatus = newToolBarBottom < minHideBottom && direction > 0 ? true : null;

				this.callToolBarHideAnimation(hideStatus, true);
				this.swipeCoordY = coordY;
				return;
			}

			if (Math.abs(deltaY) > 20) {
				blindList.style.height = blindList.offsetHeight - direction + 'px';

				const listStatus =
					blindList.offsetHeight > maxOpenHeight && direction < 0 && !this.mobileBlindListStatus()
						? true
						: blindList.offsetHeight < minCloseHeight && direction > 0
							? false
							: null;

				this.callToolBarHideAnimation(listStatus !== null && toolBarBottom < 0 ? false : null, true);
				this.callBlindListSwipeAnimation(listStatus);
				this.swipeCoordY = coordY;
			}
		} else if (event.type === 'touchend') {
			if (isBlindItemTouch && !this.mobileBlindListStatus()) {
				return;
			}

			const listStatus = blindList.offsetHeight < maxOpenHeight ? false : blindList.offsetHeight > minCloseHeight ? true : null;

			this.callBlindListSwipeAnimation(listStatus, false);
		}
	}

	onPanel(event: Event) {
		const arrowElement = event.currentTarget as HTMLElement;
		this.callBlindListSwipeAnimation(!arrowElement.classList.contains('opened'));
	}

	callBlindListSwipeAnimation(listStatus: boolean, itemStatus = true, duration = 500) {
		if (listStatus === null) {
			return;
		}

		const isNotScrollToBLind = this.mobileBlindListStatus() && listStatus;
		this.sessionStorageService.setSession(!!listStatus, STORAGE_NAMES.zip_mobile_menu_status);
		const blindList = this.blindListContainer?.nativeElement;

		if (blindList) {
			if (blindList.getAnimations().length > 0) {
				return;
			}

			const animationKeyframes = [{ height: blindList.offsetHeight + 'px' }, { height: listStatus ? '100vh' : '0px' }];

			const animation = this.blindListContainer.nativeElement.animate(animationKeyframes, { duration, iterations: 1, fill: 'forwards' });
			animation.onfinish = () => {
				blindList.style.height = (listStatus ? window.innerHeight : 0) + 'px';
				animation.cancel();

				if (listStatus && itemStatus) {
					this.driveAccordionItem(this.selectedBlindId, true);
				} else if (itemStatus) {
					this.collapseAccordionList();
				}

				if (!isNotScrollToBLind) {
					this.scrollToCurrentBlind();
				}
			};
		}
	}

	callToolBarHideAnimation(hideStatus: boolean, toolBarEventStatus = false, duration = 500) {
		if (hideStatus === null) {
			return;
		}

		const toolBar = document.querySelector(SELECTORS.tool_bar) as HTMLElement;
		const blindList = this.blindListContainer?.nativeElement as HTMLElement;

		if (!hideStatus && blindList) {
			blindList.style.bottom = '0px';
		}

		if (toolBar) {
			if (toolBar.getAnimations().length > 0) {
				return;
			}

			const isTabletLandscape =
				this.getScreenOrientation() === 'landscape' &&
				window.innerWidth > CONFIG.breakpoints['tablet-portrait'] &&
				window.innerWidth <= CONFIG.breakpoints['tablet-landscape'];

			const minBottom = isTabletLandscape ? '-97px' : '-122px';
			const animationOptions = { duration, iterations: 1, fill: 'forwards' };
			const animationKeyframes = [
				{
					bottom: window.innerHeight - toolBar.getBoundingClientRect().bottom + 'px',
				},
				{ bottom: hideStatus ? minBottom : '0px' },
			];

			// @ts-ignore
			const animation = toolBar.animate(animationKeyframes, animationOptions);
			animation.onfinish = () => {
				if (toolBarEventStatus) {
					this.shareDataService.setToolBarEvent(hideStatus);
				}
				if (hideStatus) {
					blindList.style.bottom = '-64px';
				}
				toolBar.style.bottom = hideStatus ? minBottom : '0px';
				animation.cancel();
			};
		}
	}

	getScreenOrientation(): string {
		return window.orientation === 0 || window.orientation === 180 ? 'portrait' : 'landscape';
	}

	scrollToCurrentBlind(behavior: ScrollBehavior = this.isWidthLessThanTabletLandscape() ? 'auto' : 'smooth') {
		this.selectedBlindId = this.getBlindIdFromStorage();

		const selectedBlind = document.getElementById(this.selectedBlindId?.toString());
		selectedBlind?.scrollIntoView({ behavior });

		if (this.isWidthLessThanTabletLandscape() && !this.mobileBlindListStatus()) {
			setTimeout(this.collapseAccordionList.bind(this));
		}
	}

	get isScreenShotLoadedOnServer(): boolean {
		return this.passDataService.isScreenShotLoadedOnServer;
	}

	set isScreenShotLoadedOnServer(status: boolean) {
		this.passDataService.isScreenShotLoadedOnServer = status;
	}

	getBlindIdFromStorage(): number | undefined {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const lastOpenedBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);

		return currentBlindId || lastOpenedBlindId;
	}

	isWidthLessThanTabletLandscape(width: number = window.innerWidth): boolean {
		return width <= CONFIG.breakpoints['tablet-landscape'];
	}

	copyBlindItem(id: number): void {
		const copyWord = 'Copy';
		const storageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
		const currentBlind = storageData.filter((blindArray) => blindArray.blind_id === id)[0];
		this.copiedBlindIndex = storageData.indexOf(currentBlind) + 1;
		const nameWithoutCopy = (name: string) => name.split(copyWord)[0].trim();
		const checkDuplicate = (name: string, currentName: string) => name.includes(nameWithoutCopy(currentName)) && name.includes(copyWord);
		const copyCounter = storageData.filter((blind: BlindData) => checkDuplicate(blind.name, currentBlind.name)).length;

		if (currentBlind) {
			const copy = { ...currentBlind };

			copy.blind_id = Math.floor(1000 + Math.random() * 9000);
			copy.name = copyCounter ? `${nameWithoutCopy(copy.name)} ${copyWord} ${copyCounter}` : `${copy.name} ${copyWord}`;
			copy.setup = { ...copy.setup };
			this.copiedBlind = copy;
		}

		if (this.isDesignType) {
			this.updateBlindsDataOnCopy();
			this.engineService.setModelDefaultPosition();
			setTimeout(this.accordionBlindItems.bind(this, null, this.copiedBlind.blind_id, this.copiedBlind.type));
		} else {
			this.engineService.copyModel(this.copiedBlind.blind_id.toString(), currentBlind.blind_id.toString(), this.copiedBlind.type);
			this.accordionBlindItems(null, this.copiedBlind.blind_id, this.copiedBlind.type);
		}
	}

	updateBlindsDataOnCopy(updateStatus = true, clearStatus = false): void {
		if (this.copiedBlind && updateStatus) {
			const storageData = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data);
			storageData.splice(this.copiedBlindIndex, 0, this.copiedBlind);
			this.blindsData = storageData;
			this.saveBlindData(storageData);
		}

		if (clearStatus) {
			this.copiedBlind = null;
			this.copiedBlindIndex = null;
		}
	}

	activatedRouteRulesHandler(): void {
		this.getActivatedRouteRules = this.route.data.subscribe(() => {
			this.getSessionDefaultValue();
			this.getSessionStorageData();
			this.setSessionStorage();

			setTimeout(this.setNameMask.bind(this));

			this.changeDetection.markForCheck();
		});
	}

	blindStatusHandler(): void {
		this.getBlindStatus = this.shareDataService.getStatus.subscribe((status: boolean) => {
			this.isBlindName = status;
			this.changeDetection.markForCheck();
		});
	}

	blindsUpdateHandler(): void {
		this.getBlindsUpdated = this.shareDataService.getBlindsUpdated.subscribe(() => {
			if (this.blindsData.length === 0) {
				this.deletedLastBlindItem();
			}
		});
	}

	modelLoadedHandler(): void {
		this.getModelLoadedStatus = this.shareDataService.getSceneCreated.subscribe((res: string) => {
			const id = this.setBlindSelectData.id || this.getBlindIdFromStorage();

			if (this.isFirstLoading) {
				this.isModelLoaded = res;
				this.accordionCurrentBlind();
				this.isFirstLoading = false;
			}

			if (!this.isFirstLoading && !this.isBlindSelected && this.setBlindSelectData.type === res && this.isDesignType) {
				this.isBlindSelected = true;
				this.engineService.onChangeBlindId(id.toString());
				this.shareDataService.setBlindSelect(this.setBlindSelectData);
			}

			if (this.viewTypeChanged) {
				this.engineService.onChangeBlindId(id.toString());
				this.shareDataService.setBlindSelect({ id, type: res });
				this.viewTypeChanged = false;
			}
		});
	}

	modelDisplayHandler(): void {
		this.getModelDisplay = this.engineService.getModelDisplay().subscribe((res) => {
			this.blindDisplayStatuses = {
				...this.blindDisplayStatuses,
				[res.id]: res.isEnabled,
			};

			this.changeDetection.markForCheck();
		});
	}

	viewTypeHandler(): void {
		this.getViewType = this.shareDataService.getViewType.subscribe((res: string) => {
			const isDesign = res === VIEW_TYPES.design;
			this.viewTypeChanged = this.viewType && this.viewType !== res;
			this.viewType = res;

			if (!isDesign && this.isScreenShotLoadedOnServer) {
				this.accordionCurrentBlind();
			}

			if (!isDesign) {
				this.showCurrentBlindOnChangeView();
			} else {
				this.blindDisplayStatuses = {};
			}

			this.isDesignType = isDesign;

			this.changeDetection.markForCheck();
		});
	}

	onSwitchBlindDisplay(event: Event, id: string): void {
		event.stopPropagation();
		const icon = event.currentTarget as HTMLElement;

		icon.classList.toggle('open');

		this.engineService.toggleRootMeshById(id, icon.classList.contains('open'));
	}

	onResetBlindDisplay(event: Event, id: string): void {
		const element = event.currentTarget as HTMLElement;
		const accordionItem = element.closest(SELECTORS.blind_item);
		const active = accordionItem.classList.contains('active') || accordionItem.classList.contains('selected');

		if (this.selectedBlindId === +id) {
			if (active) {
				event.stopImmediatePropagation();
			}

			this.engineService.resetBoundingBoxDisplay(id, true);
			return;
		}

		this.engineService.resetSelectedBlind = true;
	}

	getBlindDisplayTooltip(element: any): string {
		const isClosed = !(element as HTMLElement).classList.contains('open');
		return `modals.display_model.${isClosed ? 'show' : 'hide'}`;
	}

	onSetupChanged<T>(setupItem: T, id: number): void {
		const currentBlind = this.blindsData.filter((item) => item.blind_id === id)[0];
		const key = Object.keys(setupItem)[0];

		currentBlind.setup ||= {};
		currentBlind.setup[key] = setupItem[key];
	}

	showCurrentBlindOnChangeView(): void {
		const currentBlindId = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const lastOpenedBlind = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_last_opened_blind_id);
		const blind = document.querySelector(`[id='${currentBlindId || lastOpenedBlind}']`);
		const icon = blind.querySelector(SELECTORS.blind_item_display);

		icon?.classList.add('open');
		this.engineService.toggleRootMeshById(currentBlindId, true);
	}

	deleteModel(id: string, lastBlind: boolean) {
		if (lastBlind) {
			this.engineService.onDeleteModel(id);
			this.engineService.setBackgroundImage(null, true);
			this.shareDataService.setViewType(VIEW_TYPES.design);
		} else {
			this.engineService.setModelAndBoundingBoxSettings();
			this.shareDataService.getScreenShotSaved.pipe(first()).subscribe(this.engineService.onDeleteModel.bind(this.engineService, id));
			this.shareDataService.getScreenShotAborted.pipe(first()).subscribe(this.engineService.onDeleteModel.bind(this.engineService, id));
		}
	}

	onBlindSelectedFromScene(id: string): void {
		const blindElement = this.blindListContainer.nativeElement.querySelector(`[id='${id}']`) as HTMLElement;
		const isOpen = blindElement.dataset.slided === 'true';

		if (!isOpen) {
			const blind = this.blindsData.filter((blindData) => blindData.blind_id === +id)[0];
			this.accordionBlindItems(null, +id, blind.type);
		}
	}

	onAccordionMobileBlindListHandler(): void {
		this.getAccordionMobileBlindList = this.shareDataService.getAccordionMobileBlindList.subscribe(
			this.callBlindListSwipeAnimation.bind(this),
		);
	}
}
