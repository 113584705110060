import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EngineService } from '@core/services/engine/engine.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { STORAGE_NAMES } from '@root/app.config';
import { ModalService } from '@core/services/modal/modal.service';
import { Observable, Subscription } from 'rxjs';
import { first, delay } from 'rxjs/operators';

@Component({
	selector: 'app-country-modal',
	templateUrl: './blind-type-modal.component.html',
	styleUrls: ['./blind-type-modal.component.scss', './blind-type-modal.responsive.scss'],
})
export class BlindTypeModalComponent implements OnInit {
	@ViewChild('blindTypeContent') blindTypeContent: ElementRef;

	clicked = false;
	screenshotCreated: boolean;

	subscription: Subscription;

	constructor(
		public shareDataService: ShareService,
		public dialogRef: MatDialogRef<BlindTypeModalComponent>,
		private engineService: EngineService,
		private sessionStorageService: SessionStorageService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA) public data: { status?: boolean; observable?: Observable<string> },
	) {}

	ngOnInit() {
		if (!this.data.status) {
			this.modalService.liftUpModal(this.dialogRef);
		}

		if (this.data.observable) {
			this.subscription = this.data.observable.subscribe(() => (this.screenshotCreated = true));
		}
	}

	onBlindTypeHandler(type: string): void {
		if (!this.data.observable || this.screenshotCreated) {
			this.onBlindTypeSelect(type);
		} else {
			this.subscription.unsubscribe();
			this.data.observable.pipe(first(), delay(20)).subscribe(() => {
				this.screenshotCreated = true;
				this.onBlindTypeSelect(type);
			});
		}

		this.onClose();
	}

	onClose(): void {
		this.dialogRef.close();
		this.subscription?.unsubscribe();
	}

	onBlindTypeSelect(type: string): void {
		const id = Math.floor(1000 + Math.random() * 9000);

		this.engineService.createScene(type, id.toString());

		this.clicked = true;
		this.sessionStorageService.setSession(type, STORAGE_NAMES.zip_blind_type);
		this.shareDataService.setBlindType({ type, id });
	}
}
