import { Component } from '@angular/core';

@Component({
	selector: 'app-retailer-info-popover',
	templateUrl: './retailer-info-popover.component.html',
	styleUrls: ['./retailer-info-popover.component.scss'],
})
export class RetailerInfoPopoverComponent {
	constructor() {}
}
