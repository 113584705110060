import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { SELECTORS } from '@root/app.config';

declare const $: any;

@Component({
	selector: 'app-leave-modal',
	templateUrl: './leave-modal.component.html',
	styleUrls: ['./leave-modal.component.scss', './leave-modal.responsive.scss'],
})
export class LeaveModalComponent implements OnInit, OnDestroy {
	constructor(
		public dialogRef: MatDialogRef<LeaveModalComponent>,
		private countryCodeService: SetupPageService,

		@Inject(MAT_DIALOG_DATA) public data: { embedded: boolean },
	) {}

	ngOnInit() {
		$(SELECTORS.overlay_container).addClass('internal');
	}

	ngOnDestroy() {
		$(SELECTORS.overlay_container).removeClass('internal');
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	leave(): void {
		this.countryCodeService.resetOnBeforeUnloadHandler();

		if (this.data.embedded) {
			location.reload();

			return;
		}

		this.countryCodeService.parseLocalLinkHandler();
		this.closeDialog();
	}
}
