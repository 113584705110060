import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SELECTORS, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { SVGData } from '@root/app.interfaces';

@Component({
	selector: 'app-quiz-modal',
	templateUrl: './quiz-modal.component.html',
	styleUrls: ['./quiz-modal.component.scss', './quiz-modal.responsive.scss'],
})
export class QuizModalComponent implements OnInit, AfterViewInit, OnDestroy {
	getViewChangeHandler: Subscription;

	isMobile: boolean;
	isDesignType: boolean;
	isQuizReviewed: boolean;

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		infinite: false,
		swipe: false,
		arrows: false,
		autoplay: false,
		adaptiveHeight: true,
		customPaging: () => {
			return '<div class="slick-dot-custom"></div>';
		},
	};

	quizIconsFolder = '/assets/icons/quiz/';
	slidesQuantity: number;
	currentSlideIndex: number;
	svgData: SVGData[] = [];
	IVQuizNames = ['move', 'resize', 'rotate', 'display', 'send'];
	DYBQuizNames = ['size', 'toggle', 'send'];

	constructor(
		private matDialogRef: MatDialogRef<QuizModalComponent>,
		private setupPageService: SetupPageService,
		private changeDetection: ChangeDetectorRef,
		private shareDataService: ShareService,
		private sessionStorageService: SessionStorageService,
		protected sanitizer: DomSanitizer,
	) {}

	ngOnInit(): void {
		this.isMobile = this.setupPageService.mobileAndTabletCheck();
		this.quizCloseHandler();
		this.quizStatusHandler();
		this.getViewTypeFormStorage();
		this.changeStyleForOverlay();
		this.getSVG();
	}

	ngAfterViewInit(): void {
		this.changeDetection.detectChanges();
	}

	ngOnDestroy(): void {
		this.getViewChangeHandler.unsubscribe();
	}

	closeDialog(buttonType: string = ''): void {
		this.matDialogRef.close(buttonType);
	}

	carouselHandler(event: any): void {
		const slick = event.slick;
		const slideTrack = slick.$slideTrack;
		const children = slideTrack[0].children;

		let isColumnMode = true;

		for (const child of children) {
			if (window.getComputedStyle(child).flexDirection !== 'column') {
				isColumnMode = false;
				break;
			}
		}

		if (event.event?.type === 'init' && isColumnMode) {
			const slider = slick.$slider;
			const firstImg = slider.find(SELECTORS.quiz_icon);
			firstImg.on('load', () => {
				const slide = slick.$slides[0];
				const img = slide.querySelector(SELECTORS.quiz_icon);
				const height = img.offsetHeight;
				const firstChildHeight = slide.children[0].offsetHeight;
				slick.$list.height(firstChildHeight + height);
			});
		}

		this.slidesQuantity = slick.slideCount;
		this.currentSlideIndex = slick.currentSlide;
		this.isQuizReviewed = this.currentSlideIndex === this.slidesQuantity - 1 || this.isQuizReviewed;
	}

	onLoadImage(event): void {
		let isColumnMode = true;
		const slideTrack = document.querySelector(SELECTORS.slick_track) as HTMLElement;

		if (!slideTrack) {
			return;
		}

		const children = slideTrack.children;

		for (const child of children) {
			if (window.getComputedStyle(child).flexDirection !== 'column') {
				isColumnMode = false;
				break;
			}
		}

		if (!isColumnMode) {
			return;
		}

		const img = event.target as HTMLElement;
		const height = img.offsetHeight;
		const firstChildHeight = (img.parentElement.firstElementChild as HTMLElement).offsetHeight;

		const slick = document.querySelector(SELECTORS.slick_list) as HTMLElement;
		slick.style.height = `${firstChildHeight + height}px`;
	}

	quizCloseHandler(): void {
		this.getViewChangeHandler = this.shareDataService.getViewType.subscribe((type: string) => {
			this.isDesignType = type === VIEW_TYPES.design;

			if (this.isDesignType) {
				this.closeDialog();
			} else {
				const quizStatuses = this.sessionStorageService.getSession(STORAGE_NAMES.zip_quiz_status);

				quizStatuses.iv = true;

				this.sessionStorageService.setSession(quizStatuses, STORAGE_NAMES.zip_quiz_status);
			}

			this.getSVG();
		});
	}

	quizStatusHandler(): void {
		this.matDialogRef.afterOpened().subscribe(this.switchQuizStatus.bind(this, true));
		this.matDialogRef.afterClosed().subscribe((res) => {
			this.switchQuizStatus(false, res);
			this.updateReviewedQuizStatus(!!res);
		});
	}

	switchQuizStatus(status: boolean, buttonType: string): void {
		this.shareDataService.setQuizStatus({
			status,
			buttonType,
		});
		this.setUntouchableOverlay(status);
	}

	updateReviewedQuizStatus(status: boolean) {
		const quizReviewedStatus = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_quiz_reviewed_status);
		const currentQuizReviewedStatus = {
			...quizReviewedStatus,
			[this.isDesignType ? 'dyb' : 'iv']: status,
		};

		this.sessionStorageService.setSession(currentQuizReviewedStatus, STORAGE_NAMES.zip_quiz_reviewed_status);
	}

	setUntouchableOverlay(status: boolean): void {
		const overlay = document.querySelector(SELECTORS.overlay_container);

		overlay.classList.toggle('untouchable', status);
	}

	getViewTypeFormStorage(): void {
		const type = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type);
		const quizReviewedStatus = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_quiz_reviewed_status);

		this.isDesignType = type === VIEW_TYPES.design;
		this.isQuizReviewed = this.isDesignType ? quizReviewedStatus?.dyb : quizReviewedStatus?.iv;
	}

	changeStyleForOverlay(): void {
		const overlay = this.matDialogRef['_ref']?.['overlayRef'];
		const overlayElement = overlay.overlayElement as HTMLElement;
		const hasBackdrop = overlay['_config'].hasBackdrop;

		overlayElement.parentElement.style.zIndex = hasBackdrop ? '1000' : '999';
	}

	getSVG(): void {
		const svgNames = this.isDesignType ? this.DYBQuizNames : this.IVQuizNames;
		this.svgData = [];

		svgNames.forEach((name) =>
			this.svgData.push({
				name,
				file: this.sanitizer.bypassSecurityTrustResourceUrl(`${this.quizIconsFolder}${name}.svg`),
			}),
		);
	}
}
