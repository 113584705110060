<div class="mc-summary__block header">
	<a class="mc-summary__block-link--back" (click)="toHomePage()">{{ 'summary_table.buttons.back' | translate }}</a>
	<div class="mc-summary__block-container">
		<h1 class="mc-summary__block-title" [innerHTML]="'summary_table.blocks.header.title' | translate"></h1>
		<p class="mc-summary__block-text" *ngIf="!embeddedRetailer">
			{{ 'summary_table.blocks.header.text' | translate }}
		</p>
	</div>
</div>
<div class="mc-summary-loader" *ngIf="!hasBlindsData"></div>
<div class="mc-summary__table" *ngIf="hasBlindsData">
	<div class="mc-summary__table-filters-wrapper">
		<div class="mc-summary__table-filters" *ngIf="types?.length > 1">
			<button class="mc-summary__table-button outdoor" [class]="{ active: type === 'outdoor' }" (click)="onSelectFilter('outdoor')">
				{{ 'summary_table.types.outdoor' | translate }}
			</button>
			<button class="mc-summary__table-button interior" [class]="{ active: type === 'interior' }" (click)="onSelectFilter('interior')">
				{{ 'summary_table.types.interior' | translate }}
			</button>
		</div>
	</div>
	<div class="mc-table">
		<div class="mc-table__main" *media="breakpoints['phone-portrait'].min">
			<div class="mc-table__blind-block">
				{{ 'summary_table.table_fields.title' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.material">
				{{ 'summary_table.table_fields.material' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.material">
				{{ 'summary_table.table_fields.material_colour' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.size">
				{{ 'summary_table.table_fields.size' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.frame_color">
				{{ 'summary_table.table_fields.frame_colour' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.frame_style">
				{{ 'summary_table.table_fields.frame_top_style' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="type === 'outdoor'">
				{{ 'summary_table.table_fields.bottom_bar_protection' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="type === 'interior'">
				{{ 'summary_table.table_fields.bottom_chanel' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="type === 'interior'">
				{{ 'summary_table.table_fields.colour_of_fixtures' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.operation">
				{{ 'summary_table.table_fields.operation' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.operation">
				{{ 'summary_table.table_fields.optional' | translate }}
			</div>
			<div class="mc-table__blind-block" *ngIf="blindOptionAvailability.mounting">
				{{ 'summary_table.table_fields.mounting' | translate }}
			</div>

			<p class="mc-table-text" *ngIf="hasExtraSizeBlind">
				{{ 'summary_table.table_fields.text' | translate }}
			</p>
		</div>

		<ngx-slick-carousel class="carousel mc-carousel" #slickModal="slick-carousel" [config]="slideConfig">
			<ng-container *ngFor="let blind of filteredBlindsData; let i = index; trackBy: trackByIndex">
				<div ngxSlickItem class="slide">
					<div class="mc-table__blind" [class]="blind.type">
						<div class="mc-table__blind-header">
							<span class="mc-table__blind-logo"></span>
							<span class="mc-table__blind-type">
								{{ 'summary_table.types.' + blind.type | translate }}
							</span>
							<span class="mc-table__blind-number">
								{{ blindsData.indexOf(blind) + 1 }}
							</span>
						</div>
						<div class="mc-table__blind-block title">
							<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
								{{ 'summary_table.table_fields.title' | translate }}
							</span>
							{{ blind.name }}
						</div>
						<div class="mc-table__blind-block" *ngIf="blind.setup.material">
							<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
								{{ 'summary_table.table_fields.material' | translate }}
							</span>
							{{ blind.setup.material.name }}
						</div>
						<div class="mc-table__blind-block" *ngIf="blind.setup.material">
							<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
								{{ 'summary_table.table_fields.material_colour' | translate }}
							</span>
							<span
								*ngIf="
									blind.setup.material.type === '95%_sunscreen_mesh' ||
									blind.setup.material.type === 'light_blocking' ||
									blind.setup.material.type === 'light_filtering'
								"
							>
								{{ blind.setup.material.color_name }}
							</span>
							<div class="mc-minus" *ngIf="blind.setup.material.type === 'clear_pvc'">
								{{ blind.setup.material.color_name }}
							</div>
							<div
								class="mc-table__blind-block-color"
								*ngIf="blind.setup.material.type !== 'clear_pvc'"
								[style.background]="
									'linear-gradient(315deg,' + blind.setup.material.color + ' 58.49%, ' + blind.setup.material.color + '80 100%)'
								"
							></div>
						</div>
						<div class="mc-table__blind-block" *ngIf="blind.setup.size">
							<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
								{{ 'summary_table.table_fields.size' | translate }}
							</span>
							<span class="mc-table__blind-block--size-name" [ngClass]="{ 'extra-size': blind.setup.size.extra_size }">
								{{ blind.setup.size.width }} x {{ blind.setup.size.height }} mm
							</span>
							<span
								class="mc-table__blind-block--info-icon"
								(click)="$event.stopPropagation()"
								#sizePopover="ngbPopover"
								#sizePopoverButton
								(shown)="onPopover(sizePopover, sizePopoverButton)"
								(hidden)="onPopover(sizePopover, sizePopoverButton)"
								placement="top"
								popoverClass="mc-popover mc-popover-blind-info"
								[ngbPopover]="'summary_table.modals.size' | translate"
							></span>
						</div>

						<div *ngIf="blind.setup.frames">
							<div class="mc-table__blind-block">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.frame_colour' | translate }}
								</span>
								<span class="mc-table__blind-block--color-name">
									{{ blind.setup.frames.frame_color.name }}
									<span *ngIf="blind.setup.frames.frame_color.name === 'Other'">
										&nbsp;/ {{ blind.setup.frames.frame_color.color_name }}
									</span>
									<span *ngIf="blind.setup.frames.frame_color.name === 'Custom'">
										Colour &nbsp;/ {{ blind.setup.frames.frame_color.color }}
									</span>
								</span>
								<div
									*ngIf="!blind.setup.frames.frame_color.is_custom"
									class="mc-table__blind-block-color"
									[style.background]="
										'linear-gradient(315deg,' +
										blind.setup.frames.frame_color.color +
										' 58.49%, ' +
										blind.setup.frames.frame_color.color +
										'80 100%)'
									"
								></div>
							</div>
							<div class="mc-table__blind-block" *ngIf="blind.setup.frames.top_style">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.frame_top_style' | translate }}
								</span>
								{{ blind.setup.frames.top_style.name }}
							</div>
							<div class="mc-table__blind-block" *ngIf="blind.setup.frames.bottom_bar && blind.type === 'outdoor'">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.bottom_bar_protection' | translate }}
								</span>
								{{ blind.setup.frames.bottom_bar?.name || ('summary_table.table_fields.standart_strip' | translate) }}
							</div>
							<div class="mc-table__blind-block" *ngIf="blind.setup.frames && type === 'interior'">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.bottom_chanel' | translate }}
								</span>
								<span>
									{{ 'summary_table.fields_value.bottom_chanel.' + (blind.setup.frames.optionals.length ? 'yes' : 'no') | translate }}
								</span>
							</div>
							<div class="mc-table__blind-block" *ngIf="type === 'interior'">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.colour_of_fixtures' | translate }}
								</span>
								<span class="mc-table__blind-block--color-name">
									{{ blind.setup.fixtures_color.name }}
								</span>
								<div
									class="mc-table__blind-block-color"
									[style.background]="
										'linear-gradient(315deg,' +
										blind.setup.fixtures_color.color +
										' 58.49%, ' +
										blind.setup.fixtures_color.color +
										'80 100%)'
									"
								></div>
							</div>
						</div>

						<div *ngIf="blind.setup.operation">
							<div class="mc-table__blind-block">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.operation' | translate }}
								</span>
								{{ blind.type === 'outdoor' ? blind.setup.operation.long_name : blind.setup.operation.name }}
							</div>
							<div class="mc-table__blind-block">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.optional' | translate }}
								</span>
								<div class="mc-table__blind-block-container" *ngIf="blind.setup.operation.optional.length > 0">
									<div *ngFor="let option of blind.setup.operation.optional; let i = index">
										<ng-container *media="breakpoints['phone-portrait'].min">
											<span *ngIf="i">&nbsp;/</span>
										</ng-container>
										{{ option.name }}
									</div>
								</div>

								<div class="mc-minus" *ngIf="!blind.setup.operation.optional.length"></div>
							</div>
						</div>

						<div *ngIf="blind.setup.mounting">
							<div class="mc-table__blind-block">
								<span class="mc-table__blind-block-label" *media="breakpoints['phone'].max">
									{{ 'summary_table.table_fields.mounting' | translate }}
								</span>
								{{ blind.setup.mounting.name }}
							</div>
						</div>

						<div class="mc-table__blind-footer" [ngClass]="{ production: isProd }">
							<div class="mc-table__blind-edit" (click)="onEditBlind(blind)">
								<span>{{ 'summary_table.buttons.edit' | translate }}</span>
								<i class="mc-table__blind-edit-icon"></i>
							</div>
							<div class="mc-table__blind-iv" *ngIf="!isProd" (click)="onImageVisualisationBlind(blind)">
								<i class="mc-table__blind-iv-icon"></i>
							</div>
							<div class="mc-table__blind-delete" (click)="onDeleteBlind(blind.blind_id)">
								<span>{{ 'summary_table.buttons.delete' | translate }}</span>
								<i class="mc-table__blind-delete-icon"></i>
							</div>
						</div>
					</div>

					<p class="mc-table-text mobile" *ngIf="blind.setup.size.extra_size">
						{{ 'summary_table.table_fields.text' | translate }}
					</p>
				</div>
			</ng-container>
		</ngx-slick-carousel>
	</div>
	<div class="mc-summary__block-link--add" (click)="onAddBlindItem()">
		{{ 'summary_table.buttons.add' | translate }}
	</div>
</div>

<div class="mc-summary__block request">
	<div class="mc-summary__block-container">
		<div class="mc-summary__block-title">
			{{ 'summary_table.blocks.request.title' | translate }}
		</div>
		<div class="mc-summary__block-buttons" #requestBlockButtons>
			<span class="mc-summary__block-link--request" (click)="setBlindsTableData()">
				{{ 'summary_table.buttons.request' | translate }}
			</span>
			<button
				class="mc-summary__block-link--download"
				[ngClass]="{ loading: downloading }"
				[disabled]="downloading || !this.isScreenshotLoadedOnServer"
				(click)="putDownloadData()"
			>
				{{ 'summary_table.buttons.download' | translate }}
			</button>
		</div>
		<div
			class="mc-summary__block-text"
			[innerHTML]="
				(embeddedRetailer ? 'summary_table.blocks.request.text_embedded_retailer' : 'summary_table.blocks.request.text') | translate
			"
		></div>
		<div class="mc-summary__block-copy" *ngIf="embeddedRetailer" [innerHTML]="'summary_table.blocks.request.copy' | translate"></div>
	</div>

	<div class="mc-summary__block-container embedded-retailer" *ngIf="embeddedRetailer">
		<div class="mc-summary__block-title">
			{{ 'summary_table.blocks.request.title_embedded_retailer' | translate }}
		</div>
		<div class="mc-summary__block--retailer-card">
			<div class="mc-retailer-card-header">
				<div class="mc-retailer-card-item name">
					{{ embeddedRetailer.name }}
				</div>
				<img class="mc-retailer-card-item logo" *ngIf="embeddedRetailer.logo" [src]="embeddedRetailer.logo" [alt]="embeddedRetailer.name" />
			</div>
			<div class="mc-retailer-card-content">
				<div class="mc-retailer-card-item address" *ngIf="embeddedRetailer.show_address">
					{{ embeddedRetailer.full_address }}
				</div>
				<div class="mc-retailer-card-item types">
					<span
						class="mc-retailer-card-info-icon"
						(click)="isMobile ? onOpenRetailerInfoModal() : null"
						[ngbPopover]="typesPopover"
						[triggers]="isMobile ? '' : 'mouseenter:mouseleave'"
						container="body"
						placement="top top-left"
						popoverClass="mc-popover mc-popover-card-info"
					></span>
					<i class="mc-retailer-card-type outdoor" *ngIf="embeddedRetailer.product === 'outdoor' || embeddedRetailer.product === 'all'"></i>
					<i
						class="mc-retailer-card-type interior"
						*ngIf="embeddedRetailer.product === 'interior' || embeddedRetailer.product === 'all'"
					></i>
					<i
						class="mc-retailer-card-type showroom"
						*ngIf="embeddedRetailer.store_type === 'showroom' || embeddedRetailer.store_type === 'all'"
					></i>
					<i
						class="mc-retailer-card-type mobile"
						*ngIf="embeddedRetailer.store_type === 'mobile' || embeddedRetailer.store_type === 'all'"
					></i>
					<i class="mc-retailer-card-type service" *ngIf="embeddedRetailer.service_and_repairs"></i>
					<i class="mc-retailer-card-type swiftee" *ngIf="+embeddedRetailer.swiftee"></i>
				</div>
			</div>
		</div>
		<div class="mc-summary__block-buttons" #mobileRequestBlockButtons>
			<span class="mc-summary__block-link--request" (click)="setBlindsTableData()">
				{{ 'summary_table.buttons.request' | translate }}
			</span>
			<button
				class="mc-summary__block-link--download"
				[ngClass]="{ loading: downloading }"
				[disabled]="downloading || !this.isScreenshotLoadedOnServer"
				(click)="putDownloadData()"
			>
				{{ 'summary_table.buttons.download' | translate }}
			</button>
		</div>
	</div>
</div>
<div class="mc-summary__block save" *ngIf="!embeddedRetailer">
	<div class="mc-summary__block-container">
		<div class="mc-summary__block-title">
			{{ 'summary_table.blocks.save.title' | translate }}
		</div>
		<div class="mc-summary__block-text">
			{{ 'summary_table.blocks.save.text' | translate }}
		</div>
		<div class="mc-summary__block-buttons">
			<button
				class="mc-summary__block-link--save"
				[ngClass]="{ loading: saving }"
				[disabled]="saving"
				id="summary-email-button"
				(click)="putSaveBlinds()"
			>
				{{ 'summary_table.buttons.save' | translate }}
			</button>
		</div>
		<div class="mc-summary__block-text prompt" [innerHTML]="'summary_table.blocks.save.prompt' | translate"></div>
	</div>
</div>
<div class="mc-summary__block panel">
	<div class="mc-summary__block-container">
		<div class="mc-summary__block-buttons">
			<span class="mc-summary__block-link--request" (click)="setBlindsTableData()">
				{{ 'summary_table.buttons.request' | translate }}
			</span>
			<span class="mc-summary__block-link--request mobile" (click)="setBlindsTableData()">
				{{ 'summary_table.buttons.request_shorter' | translate }}
			</span>
			<button
				class="mc-summary__block-link--download"
				[ngClass]="{ loading: downloading }"
				[disabled]="downloading || !this.isScreenshotLoadedOnServer"
				(click)="putDownloadData()"
			>
				{{ 'summary_table.buttons.download' | translate }}
			</button>
		</div>
	</div>
</div>

<ng-template #typesPopover>
	<app-retailer-info-popover></app-retailer-info-popover>
</ng-template>
