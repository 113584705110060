<div class="mc-blind-container">
	<div
		class="mc-blind__swipe-area"
		(touchstart)="onTouchEvent($event)"
		(touchmove)="onTouchEvent($event)"
		(touchend)="onTouchEvent($event)"
	>
		<div class="mc-blind-mobile-slider" [class]="{ arrow: !isMobile }" *ngIf="blindsData && blindsData.length">
			<div class="mc-blind-mobile-arrow" *ngIf="!isMobile" [class]="{ opened: mobileBlindListStatus() }" (click)="onPanel($event)"></div>
		</div>
		<div class="mc-blind-list" *ngIf="blindsData && blindsData.length" #blindListContainer [ngClass]="{ open: mobileBlindListStatus() }">
			<div
				class="mc-blind-item"
				*ngFor="let blind of blindsData; let i = index"
				data-id="{{ blind.blind_id }}"
				[class]="blind.type"
				[ngClass]="{ selected: selectedBlindId === blind.blind_id }"
			>
				<div
					class="mc-blind-item__header"
					[class]="{ collapsed: isToolBarCollapsed }"
					(click)="accordionBlindItems($event, blind.blind_id, blind.type)"
				>
					<div class="mc-blind-item__index">{{ i + 1 }}</div>
					<div class="mc-blind-item__name-container">
						<div class="mc-blind-item__name">
							<span *ngIf="!isToolBarCollapsed">{{ blind.type | titlecase }}</span>
							<span *ngIf="!isToolBarCollapsed && blind.name">: </span>
							<span *ngIf="blind.name">{{ blind.name }}</span>
						</div>
						<div class="mc-blind-item__size" *ngIf="blind.setup?.size && !isToolBarCollapsed">
							{{ blind.setup?.size['width'] + ' x ' + blind.setup?.size['height'] + ' mm' }}
						</div>
					</div>

					<span
						class="mc-blind-item__setup-display"
						[class]="{ open: blindDisplayStatuses[blind.blind_id] }"
						#displayIcon
						*ngIf="!isDesignType"
						(click)="onSwitchBlindDisplay($event, blind.blind_id.toString())"
						#popover="ngbPopover"
						placement="bottom"
						triggers="mouseenter:mouseleave"
						container="body"
						popoverClass="mc-popover mc-popover-image-visualisation"
						[ngbPopover]="!isMobile ? (getBlindDisplayTooltip(displayIcon) | translate) : null"
					></span>
					<span
						class="mc-blind-item__setup-copy"
						*ngIf="!isToolBarCollapsed"
						#popover="ngbPopover"
						placement="bottom"
						triggers="mouseenter:mouseleave"
						container="body"
						popoverClass="mc-popover mc-popover-image-visualisation"
						[ngClass]="{ active: copyPopover.isOpen() }"
						[ngbPopover]="!isMobile && !copyPopover.isOpen() ? ('modals.copy_blind.tooltip' | translate) : null"
					>
						<div
							#copyPopover="ngbPopover"
							container="body"
							placement="left"
							(click)="$event.stopPropagation()"
							[ngbPopover]="copyBlindContent"
							popoverClass="mc-popover mc-popover-copy"
						>
							Copy
						</div>
					</span>
					<span
						class="mc-blind-item__setup-delete"
						*ngIf="!isToolBarCollapsed"
						#popover="ngbPopover"
						placement="bottom"
						triggers="mouseenter:mouseleave"
						container="body"
						popoverClass="mc-popover mc-popover-image-visualisation"
						[ngClass]="{ active: deletePopover.isOpen() }"
						[ngbPopover]="!isMobile && !deletePopover.isOpen() ? ('modals.delete_blind.tooltip' | translate) : null"
					>
						<div
							#deletePopover="ngbPopover"
							placement="left"
							container="body"
							(click)="$event.stopPropagation()"
							[ngbPopover]="deleteBlindContent"
							popoverClass="mc-popover mc-popover-delete"
						>
							Delete
						</div>
					</span>
					<span
						class="mc-blind-item__setup-reset"
						*ngIf="!isDesignType && !isToolBarCollapsed"
						(click)="onResetBlindDisplay($event, blind.blind_id.toString())"
						#popover="ngbPopover"
						placement="bottom"
						triggers="mouseenter:mouseleave"
						container="body"
						popoverClass="mc-popover mc-popover-image-visualisation"
						[ngbPopover]="!isMobile ? ('modals.reset' | translate) : null"
					></span>
					<div class="mc-blind-item__arrow" *ngIf="!isToolBarCollapsed || isDesignType">Arrow</div>
				</div>

				<div class="mc-blind-item__setup-container hidden" *ngIf="blind.type === 'outdoor'">
					<div class="mc-blind-item__setup-content mc-config">
						<app-outdoor-list
							[index]="i"
							[blindsListComponent]="this"
							(setupData)="onSetupChanged($event, blind.blind_id)"
						></app-outdoor-list>
					</div>
				</div>

				<div class="mc-blind-item__setup-container hidden" *ngIf="blind.type === 'interior'">
					<div class="mc-blind-item__setup-content mc-config">
						<app-interior-list
							[index]="i"
							[blindsListComponent]="this"
							(setupData)="onSetupChanged($event, blind.blind_id)"
						></app-interior-list>
					</div>
				</div>

				<ng-template #deleteBlindContent>
					<div class="mc-blind-item__delete-title">
						{{ 'modals.delete_blind.title' | translate }}
					</div>
					<button class="mc-blind-item__button yes" (click)="deleteBlindItem(blind.blind_id)">
						{{ 'modals.delete_blind.buttons.yes' | translate }}
					</button>
					<button class="mc-blind-item__button no" (click)="[deletePopover?.close(), $event.stopPropagation()]">
						{{ 'modals.delete_blind.buttons.no' | translate }}
					</button>
				</ng-template>

				<ng-template #copyBlindContent>
					<div class="mc-blind-item__delete-title">
						{{ 'modals.copy_blind.title' | translate }}
					</div>
					<button class="mc-blind-item__button yes" (click)="copyBlindItem(blind.blind_id)">
						{{ 'modals.delete_blind.buttons.yes' | translate }}
					</button>
					<button class="mc-blind-item__button no" (click)="[copyPopover?.close(), $event.stopPropagation()]">
						{{ 'modals.delete_blind.buttons.no' | translate }}
					</button>
				</ng-template>
			</div>
		</div>
	</div>
	<app-add-blind [isToolBarCollapsed]="isToolBarCollapsed"></app-add-blind>
</div>
