import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[media]',
})
export class ResizeDirective {
	@Input() set media(query: string) {
		// cleanup old listener
		if (this.removeListener) {
			this.removeListener();
		}

		this.setListener(query);
	}

	private hasView = false;
	private removeListener: () => void;

	constructor(
		private readonly viewContainer: ViewContainerRef,
		private readonly template: TemplateRef<HTMLElement>,
	) {}

	private setListener(query: string) {
		const mediaQueryList: MediaQueryList = window.matchMedia(query);

		// Define the listener for media query changes
		const listener = (event: MediaQueryListEvent) => {
			// create view if true and not created already
			if (event.matches && !this.hasView) {
				this.hasView = true;
				this.viewContainer.createEmbeddedView(this.template);
			}
			// destroy view if false and created
			if (!event.matches && this.hasView) {
				this.hasView = false;
				this.viewContainer.clear();
			}
		};

		// Initial call to the listener with a proper MediaQueryListEvent
		listener({ matches: mediaQueryList.matches } as MediaQueryListEvent);

		// Add event listener for changes
		mediaQueryList.addEventListener('change', listener);

		// Add cleanup listener
		this.removeListener = () => mediaQueryList.removeEventListener('change', listener);
	}
}
