import { Component, HostListener, OnInit } from '@angular/core';
import { SelectBackgroundModalComponent } from '@shared/modals/select-background-modal/select-background-modal.component';
import { SaveWorkModalComponent } from '@shared/modals/save-work-modal/save-work-modal.component';
import { CONFIG, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { ModalService } from '@core/services/modal/modal.service';

@Component({
	selector: 'app-control-panel',
	templateUrl: './control-panel.component.html',
	styleUrls: ['./control-panel.component.scss', './control-panel.responsive.scss'],
})
export class ControlPanelComponent implements OnInit {
	getIVResetStatus: Subscription;

	isMobile: boolean;
	shutterControlStatus = true;
	isDesignType: boolean = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_view_type) === VIEW_TYPES.design;

	resetStatus = false;
	resetPopoverPosition = 'left';

	@HostListener('window:orientationchange', ['$event'])
	onOrientationChange() {
		this.resetStatus = true;
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobile = this.setupPageService.mobileAndTabletCheck();
		const status = this.isMobile ? false : window.innerWidth > CONFIG.breakpoints['tablet-landscape'];
		const phoneSize = window.innerWidth <= CONFIG.breakpoints['phone'];

		if (status !== this.shutterControlStatus) {
			this.shutterControlStatus = status;
			this.shareService.setIVShutterControl(this.shutterControlStatus);
		}

		this.resetPopoverPosition = !phoneSize ? 'left top-left' : phoneSize ? 'bottom' : 'top-left';
	}

	constructor(
		private sessionStorageService: SessionStorageService,
		private setupPageService: SetupPageService,
		private engineService: EngineService,
		private shareService: ShareService,
		private modalService: ModalService,
	) {}

	ngOnInit(): void {
		this.getIVResetStatus = this.shareService.getIVResetStatus.subscribe((status) => {
			this.resetStatus = status;
		});
		this.onResize();
	}

	onBackgroundSelectControl(event: Event) {
		const currentTarget = event.currentTarget as HTMLElement;

		currentTarget.classList.add('active');

		const dialogRef = this.modalService.openResponsiveDialog({
			component: SelectBackgroundModalComponent,
			width: 'max-content',
			maxWidth: '940px',
			height: 'auto',
			minHeight: '200px',
		});

		dialogRef.afterClosed().subscribe(() => currentTarget.classList.remove('active'));
	}

	onSaveControl(event: Event) {
		const currentTarget = event.currentTarget as HTMLElement;

		currentTarget.classList.add('active');

		const dialogRef = this.modalService.openResponsiveDialog({
			component: SaveWorkModalComponent,
			width: 'max-content',
			maxWidth: '690px',
			height: 'auto',
			minHeight: '200px',
		});

		dialogRef.afterClosed().subscribe(() => currentTarget.classList.remove('active'));
	}

	onResetIVScreen() {
		this.engineService.boundingBoxesSettings = {};
		this.engineService.sceneOrientation = this.setupPageService.getScreenOrientation();
		this.engineService.setIVCameraSettings();
		this.engineService.setGizmoControl(true);
		this.engineService.setIVSampleProject();
		this.engineService.createModelsFromStorage();
		this.engineService.resetLineByPoints(false);
	}
}
