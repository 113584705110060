<div class="mc-modal__quiz">
	<div class="mc-modal__close" (click)="closeDialog(currentSlideIndex === slidesQuantity - 1 || this.isQuizReviewed ? 'next' : '')"></div>

	<ngx-slick-carousel
		class="carousel mc-modal__quiz-carousel"
		#slickCarousel="slick-carousel"
		(init)="carouselHandler($event)"
		(afterChange)="carouselHandler($event)"
		[config]="slideConfig"
	>
		<div class="mc-modal__quiz-carousel-item" ngxSlickItem *ngFor="let item of svgData; let i = index">
			<div class="mc-modal__quiz-text">
				{{ 'modals.quiz.' + item.name + (isMobile ? '_mobile' : '') | translate }}
			</div>
			<ng-container *ngTemplateOutlet="quizButtons"></ng-container>
			<object class="mc-modal__quiz-img" type="image/svg+xml" [data]="item.file" (load)="onLoadImage($event)"></object>
		</div>
	</ngx-slick-carousel>

	<ng-container *ngTemplateOutlet="quizButtons"></ng-container>
</div>

<ng-template #quizButtons>
	<div class="mc-modal__quiz-buttons" *ngIf="slidesQuantity">
		<button class="mc-modal__quiz-button" type="button" [disabled]="!currentSlideIndex" (click)="slickCarousel.slickPrev()">
			{{ 'modals.quiz.buttons.back' | translate }}
		</button>
		<button
			class="mc-modal__quiz-button"
			type="button"
			(click)="currentSlideIndex !== slidesQuantity - 1 ? slickCarousel.slickNext() : closeDialog('next')"
		>
			{{ 'modals.quiz.buttons.next' | translate }}
		</button>
	</div>
</ng-template>
