import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SELECTORS } from '@root/app.config';

declare const $: any;

@Component({
	selector: 'app-delete-modal',
	templateUrl: './delete-modal.component.html',
	styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit, OnDestroy {
	constructor(private dialogRef: MatDialogRef<DeleteModalComponent>) {}

	ngOnInit() {
		$(SELECTORS.overlay_container).addClass('internal');
	}

	ngOnDestroy() {
		$(SELECTORS.overlay_container).removeClass('internal');
	}

	close(): void {
		this.dialogRef.close();
	}

	delete(): void {
		this.dialogRef.close(true);
	}
}
