<div class="mc-modal__ar-loading">
	<div class="mc-modal__ar-loading-header">
		<div class="mc-modal__ar-loading-header-logo" (click)="onClose('logo')"></div>
		<div class="mc-modal__ar-loading-header-close" *ngIf="(!data || data?.model_exported) && !quizOpened" (click)="onClose('close')">
			<ng-container *media="breakpoints['phone-portrait'].min">
				{{ 'modals.ar_loading.buttons.close' | translate }}
			</ng-container>
		</div>

		<div class="mc-modal__ar-loading-header-close mc-modal__ar-loading-header-close--skip" *ngIf="quizOpened" (click)="onSkip()">
			{{ 'modals.ar_loading.buttons.skip' | translate }}
		</div>
	</div>

	<ng-container *ngIf="!isArLoaded || isARNotCompatible">
		<div
			class="mc-modal__ar-loading-loader"
			[class]="{
				loaded: isArLoaded && !isARNotCompatible && !loading,
				'not-compatible': isARNotCompatible && !loading,
			}"
		></div>
		<div class="mc-modal__ar-loading-text">
			{{ (loading ? 'modals.ar_loading.text' : isArLoaded && isARNotCompatible ? 'modals.ar_loading.not_compatible' : '') | translate }}
		</div>
	</ng-container>

	<div class="mc-modal__ar-quiz" *ngIf="quizOpened">
		<ngx-slick-carousel
			class="mc-modal__ar-quiz-slider"
			#slickCarousel="slick-carousel"
			[config]="slideConfig"
			(init)="carouselHandler($event)"
			(afterChange)="carouselHandler($event)"
		>
			<div class="mc-modal__ar-quiz-item" ngxSlickItem *ngFor="let type of quizSlideTypes">
				<div class="mc-modal__ar-quiz-text">
					{{ 'modals.ar_loading.quiz.' + type | translate }}
				</div>
				<div class="mc-modal__ar-quiz-img-wrapper">
					<img class="mc-modal__ar-quiz-img" [src]="quizIconsFolder + type + '-ar.svg'" />
				</div>
			</div>
		</ngx-slick-carousel>

		<div class="mc-modal__ar-quiz-buttons" *ngIf="slidesQuantity">
			<button class="mc-modal__ar-quiz-button" type="button" [disabled]="!currentSlideIndex" (click)="slickCarousel.slickPrev()">
				{{ 'modals.quiz.buttons.back' | translate }}
			</button>
			<button
				class="mc-modal__ar-quiz-button"
				*ngIf="currentSlideIndex !== slidesQuantity - 1"
				type="button"
				(click)="slickCarousel.slickNext()"
			>
				{{ 'modals.quiz.buttons.next' | translate }}
			</button>
		</div>
	</div>
</div>
