<div class="mc-retailer-modal">
	<div class="mc-retailer-title">
		<div class="mc-retailer-logo"></div>
		Limit on 10 search results
	</div>

	<div class="mc-retailer-footer">
		<div (click)="closeDialog()" class="mc-retailer-button mc-retailer-button--close">Close</div>
	</div>
</div>
