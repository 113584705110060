import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Countries, DEVELOP_API_LINK, IS_DEVELOPMENT, STORAGE_NAMES } from '@root/app.config';
import * as streamSaver from 'streamsaver';
import { ImageData } from '@root/app.interfaces';

@Injectable({
	providedIn: 'root',
})
export class ServerDataService {
	headers = new HttpHeaders().set('Content-Type', 'application/json');
	countryCode: string;

	constructor(private http: HttpClient) {}

	serverData(): any {
		const retailerKey = JSON.parse(sessionStorage.getItem(STORAGE_NAMES.zip_embed_key));
		const sessionCountryCode = JSON.parse(sessionStorage.getItem(STORAGE_NAMES.zip_country_code));
		const code = !!sessionCountryCode
			? sessionCountryCode
			: !!this.countryCode
				? this.countryCode
				: !!retailerKey
					? Countries[0].code
					: sessionCountryCode;
		const countryData = Countries.filter((item) => item.code === code)[0];
		const apiLink = countryData?.data_store;
		const countryName = countryData?.name.toLocaleLowerCase().split(' ').join('-');

		const link = IS_DEVELOPMENT() ? `${DEVELOP_API_LINK}/${countryName}` : apiLink;

		return {
			main_link: link,
			rest: {
				config: link + '/3dc/configurations',
				config_test: link + '/3dc/configurations-test',
				embedded_linK: link + '/far/retailers/embedded-link',
				retailers: link + '/far/retailers',
				settings: link + '/far/settings',
				outdoor: link + '/far/retailers/product/outdoor',
				access: link + '/far/access',
				sessionData: link + '/3dc/sessions',
			},
			post: {
				stats: link + '/far/statistics/save',
				data: link + '/3dc/save',
				image: link + '/3dc/image/save',
				model_glb: link + '/3dc/model/save',
				email: link + '/3dc/email/email-link',
			},
			put: {
				sessionData: link + '/3dc/sessions/save',
				download: link + '/3dc/sessions/save-and-download',
			},
			delete: {
				model_glb: link + '/3dc/model/delete',
			},
			link: {
				download: '/3dc/sessions/download/pdf',
			},
		};
	}

	GetServerData(code: string, sessionKey?: string): Observable<any> {
		this.countryCode = code;
		const sessionKeyLink = sessionKey ? '/' + sessionKey : '';

		return this.http.get(this.serverData().rest.config + sessionKeyLink).pipe(catchError(this.errorMgmt));
	}

	async getPdfData(link: string) {
		const fileStream = streamSaver.createWriteStream('Ziptrak® Blinds – Summary.pdf');

		return await fetch(link)
			.then((res) => {
				const readableStream = res.body;

				if (window.WritableStream && readableStream.pipeTo) {
					return readableStream.pipeTo(fileStream);
				}
			})
			.catch(this.errorMgmt);
	}

	GetRetailersData(): Observable<any> {
		return this.http.get(`${this.serverData().rest.retailers}`).pipe(catchError(this.errorMgmt));
	}

	GetEmbeddedLink(key: string): Observable<any> {
		return this.http.get(`${this.serverData().rest.embedded_linK + '/' + key}`).pipe(catchError(this.errorMgmt));
	}

	GetSettingsData(): Observable<any> {
		return this.http.get(`${this.serverData().rest.settings}`).pipe(catchError(this.errorMgmt));
	}

	GetAccess(): Observable<any> {
		return this.http.get(`${this.serverData().rest.access}`).pipe(catchError(this.errorMgmt));
	}

	PostImageData(data: ImageData): Observable<any> {
		return this.http.post(`${this.serverData().post.image}`, data).pipe(catchError(this.errorMgmt));
	}

	PostStatsData(data): Observable<any> {
		return this.http.post(`${this.serverData().post.stats}`, data).pipe(catchError(this.errorMgmt));
	}

	PostData(data, key): Observable<any> {
		return this.http.post(`${this.serverData().post.data}/${key}`, data).pipe(catchError(this.errorMgmt));
	}

	PostEmailData(data): Observable<any> {
		return this.http.post(`${this.serverData().post.email}`, data).pipe(catchError(this.errorMgmt));
	}

	PutSessionData(data, key): Observable<any> {
		return this.http.put(`${this.serverData().put.sessionData}/${key}`, data).pipe(catchError(this.errorMgmt));
	}

	PostModelData<T>(data: T): Observable<any> {
		return this.http.post(this.serverData().post.model_glb, data).pipe(catchError(this.errorMgmt));
	}

	deleteModelData(name: string): Observable<any> {
		return this.http.delete(`${this.serverData().delete.model_glb}/${name}`).pipe(catchError(this.errorMgmt));
	}

	// Error handling
	errorMgmt(error: HttpErrorResponse): Observable<any> {
		let errorMessage: string;

		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.error(errorMessage);
		return throwError(errorMessage);
	}
}
