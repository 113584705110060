import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class SessionStorageService {
	constructor() {}

	setBlindData(data, storage): void {
		sessionStorage.setItem(storage, JSON.stringify(data));
	}

	setBlindSetupDataById(itemId, data, storage): void {
		const blinds: any = sessionStorage.getItem(storage);
		const parsedData = JSON.parse(blinds);

		if (parsedData) {
			const blindArray = _.filter(parsedData, { blind_id: itemId });
			const getBlindById = parsedData.findIndex((x) => x.blind_id === itemId);

			for (const blindObject of blindArray) {
				blindObject.setup = { ...blindObject.setup, ...data };

				parsedData[getBlindById] = blindObject;
			}

			this.setBlindData(parsedData, storage);
		}
	}

	getBlindData(storage): any {
		return JSON.parse(sessionStorage.getItem(storage));
	}

	removeBlindData(storage): any {
		sessionStorage.removeItem(storage);
	}

	getBlindItemById(itemId, storage): any {
		const blinds: any = sessionStorage.getItem(storage);

		if (blinds) {
			return _.filter(JSON.parse(blinds), { blind_id: itemId });
		}
	}

	setModelSize(data, storage): void {
		sessionStorage.setItem(storage, JSON.stringify(data));
	}

	setSession(data, storage): void {
		sessionStorage.setItem(storage, JSON.stringify(data));
	}

	getSession(storage) {
		return JSON.parse(sessionStorage.getItem(storage));
	}

	getModelSize(storage) {
		return JSON.parse(sessionStorage.getItem(storage));
	}

	removeData(storage): void {
		sessionStorage.removeItem(storage);
	}
}
