import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hexToRgb',
})
export class HexToRgbPipe implements PipeTransform {
	transform(hex: any): any {
		const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
		if (normal) {
			return normal
				.slice(1)
				.map((e) => parseInt(e, 16))
				.join()
				.replace(/,/g, ' ');
		}
		return null;
	}
}
