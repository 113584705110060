import { Component, Input } from '@angular/core';
import { MINIMUM_MATERIAL_OPACITY } from '@root/app.config';

@Component({
	selector: 'app-blind-item-description',
	templateUrl: './blind-item-description.component.html',
	styleUrls: ['./blind-item-description.component.scss', './blind-item-description.responsive.scss'],
})
export class BlindItemDescriptionComponent {
	@Input() blindItem;
	@Input() blindItemType;
	@Input() blindType;

	minimumMaterialOpacity = MINIMUM_MATERIAL_OPACITY;

	constructor() {}
}
