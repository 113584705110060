import { Injectable } from '@angular/core';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogConfig as MatDialogConfig,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { SELECTORS } from '@root/app.config';
import { getModalsConfig } from '@core/services/modal/modal.config';
import { DialogOptions } from '@root/app.interfaces';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	constructor(
		private dialog: MatDialog,
		private breakpointObserver: BreakpointObserver,
	) {}

	liftUpModal<T>(dialog: MatDialogRef<T>): void {
		const container = document.querySelector(SELECTORS.overlay_container) as HTMLElement;
		const toggleClass = (status: boolean) => container.classList.toggle('internal', status);

		toggleClass(true);

		dialog.afterClosed().subscribe(() => toggleClass.call(false));
	}

	getConfig<T>(key: string, data?: T): MatDialogConfig {
		const config = getModalsConfig()[key];

		if (data) {
			config.data = data;
		}

		return config;
	}

	openResponsiveDialog<D>(options: DialogOptions<D>): MatDialogRef<any> {
		const { component, data, width = '711px', panelClass = 'mc-modal__internal', ...config } = options;

		return this.dialog.open(component, {
			...{ width: this.getDialogWidth(width), data, panelClass },
			...config,
		});
	}

	private getDialogWidth(width: string): string {
		this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe((result) => {
			if (result.matches) {
				width = 'auto';
			}
		});

		return width;
	}
}
