import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { CLASSES, DEVICES, ROTATE_TYPES, SELECTORS, STORAGE_NAMES, VIEW_TYPES } from '@root/app.config';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { EngineService } from '@core/services/engine/engine.service';
import { PassDataService } from '@core/services/pass-data/pass-data.service';
import { ModalService } from '@core/services/modal/modal.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SelectBackgroundModalComponent } from '@shared/modals/select-background-modal/select-background-modal.component';
import { QuizModalComponent } from '@shared/modals/quiz-modal/quiz-modal.component';
import { IndexedDBService } from '@core/services/indexed-db/indexed-db.service';
import { BackgroundService } from '@core/services/background-service/background.service';
import { RotateDeviceModalComponent } from '@shared/modals/rotate-device-modal/rotate-device-modal.component';
import { AnotherDeviceModalComponent } from '@shared/modals/another-device-modal/another-device-modal.component';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { first } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';

import * as _ from 'lodash';

@Component({
	selector: 'app-view-switcher',
	templateUrl: './view-switcher.component.html',
	styleUrls: ['./view-switcher.component.scss', './view-switcher.responsive.scss'],
})
export class ViewSwitcherComponent implements OnInit, OnDestroy {
	@Input() prodStatus: boolean;

	getModelLoaded: Subscription;
	getSessionBlindsData: Subscription;
	getViewHandler: Subscription;

	isDesignYourBlind = true;
	hasBlinds = false;
	quizStatuses = {
		dyb: false,
		iv: false,
	};
	viewType: string;
	viewTypes = VIEW_TYPES;
	isSampleProject = null;
	isMobile: boolean;

	constructor(
		private shareService: ShareService,
		private engineService: EngineService,
		private sessionStorageService: SessionStorageService,
		private passDataService: PassDataService,
		private modalService: ModalService,
		private matDialog: MatDialog,
		private changeDetection: ChangeDetectorRef,
		private indexedDBService: IndexedDBService,
		private backgroundService: BackgroundService,
		public saveSessionBlind: SaveSessionBlindsService,
		private overlay: Overlay
	) {}

	ngOnInit(): void {
		this.isMobile = this.passDataService.isMobileOrTablet;
		this.viewSwitcherHandler();
		this.showQuiz();
	}

	ngOnDestroy() {
		this.getModelLoaded.unsubscribe();
		this.getSessionBlindsData.unsubscribe();
		this.getViewHandler.unsubscribe();
	}

	viewSwitcherHandler() {
		this.loadingSwitcher(true);

		this.getModelLoaded = this.shareService.getSceneCreated.subscribe(() => {
			this.hasBlinds = !_.isEmpty(this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_data));
			this.setCurrentBlindDesignSettings();
			this.modelViewerLoaderHandler(false);

			const iVStatus = this.sessionStorageService.getSession(STORAGE_NAMES.zip_image_visualisation_status);
			if (iVStatus) {
				setTimeout(this.onSwitchViewMode.bind(this, this.viewTypes.image_visualisation));
			} else {
				this.loadingSwitcher(false);
			}
		});
		this.getSessionBlindsData = this.shareService.getSessionBlindsData.subscribe((res) => (this.hasBlinds = res));
		this.sessionStorageService.setBlindData(
			this.isDesignYourBlind ? VIEW_TYPES.design : VIEW_TYPES.image_visualisation,
			STORAGE_NAMES.zip_view_type,
		);

		this.getViewHandler = this.shareService.getViewType.subscribe(this.onViewChange.bind(this));
		this.matDialog.afterOpened.subscribe((dialog) => {
			if (dialog.id === 'quiz-modal') {
				this.isSampleProject = !!this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background)?.type;
			}
		});
	}

	onSwitchViewMode(type: string) {
		const toggleType = () => (this.isDesignYourBlind = type === this.viewTypes.design);
		const ivBackgroundImage = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background);
		const sessionSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
		const ivSceneSetting = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_config).iv_scene_settings;
		const currentDevice = this.engineService.mobileAndTabletCheck() ? DEVICES.mobile : DEVICES.desktop;

		if (sessionSaved && ivBackgroundImage && ivSceneSetting && currentDevice !== ivSceneSetting.device) {
			const dialogRef = this.modalService.openResponsiveDialog({
				component: AnotherDeviceModalComponent,
				...this.modalService.getConfig(AnotherDeviceModalComponent.name),
			});

			dialogRef.afterOpened().subscribe(() => this.loadingSwitcher(false));
			dialogRef.afterClosed().subscribe((continueStatus) => {
				if (continueStatus) {
					this.onSwitchViewMode(type);
				}
			});

			return;
		}

		if (type === this.viewTypes.design || !ivBackgroundImage) {
			toggleType();
		}

		if (type !== this.viewTypes.design) {
			if (!ivBackgroundImage) {
				this.loadingSwitcher(false);
				this.matDialog.open(SelectBackgroundModalComponent, this.modalService.getConfig(SelectBackgroundModalComponent.name));
				this.sessionStorageService.removeData(STORAGE_NAMES.zip_image_visualisation_status);
			} else {
				const isUploadImage = ivBackgroundImage.id.includes('own');
				const currentBackgroundImageId = isUploadImage
					? ivBackgroundImage.id
					: ivBackgroundImage.id.split('_').slice(0, -1).join('_') + `_${this.backgroundService.getScreenOrientation()}`;

				this.indexedDBService.getImage(STORAGE_NAMES.zip_image_visualisation_background, currentBackgroundImageId).then((image) => {
					const switchViewMode = (currentImage) => {
						this.sessionStorageService.removeData(STORAGE_NAMES.zip_image_visualisation_status);
						this.engineService.setBackgroundImage(currentImage);
						this.shareService.setViewType(VIEW_TYPES.image_visualisation);
						this.passDataService.isLiveOpened = false;

						this.shareService.getViewType.pipe(first()).subscribe(toggleType);
						setTimeout(this.loadingSwitcher.bind(this, false));
					};

					if (image) {
						image = {
							...image,
							settings: ivBackgroundImage.settings,
							shutterValues: ivBackgroundImage.shutterValues,
							sampleProjectBlinds: ivBackgroundImage.sampleProjectBlinds,
						};

						switchViewMode(image);
					} else {
						this.backgroundService.getSampleProjectImage(currentBackgroundImageId).then(switchViewMode);
					}
				});
			}
		} else {
			const sessionSaved = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_session_saved);
			const changeViewType = () => {
				this.sessionStorageService.setBlindData(type, STORAGE_NAMES.zip_view_type);
				this.engineService.setBackgroundImage(null, true);
				this.modelViewerLoaderHandler(true);
				this.shareService.setViewType(this.viewTypes.design);
			};

			this.engineService.setModelAndBoundingBoxSettings();
			this.engineService.setIVSceneScreenShots().then(() => {
				this.engineService.saveModelAndBoundingBoxSettings(false);

				if (sessionSaved) {
					this.saveSessionBlind.PutStorageDataToServerPromise().then(() => changeViewType());
				} else {
					changeViewType();
				}
			});
		}
	}

	setCurrentBlindDesignSettings(): void {
		const id = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_current_blind_id);
		const type = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_blind_type);

		if (id && type) {
			this.shareService.setBlindSelect({ id, type });
		}
	}

	showQuiz(): void {
		const quizModal = this.matDialog.getDialogById('quiz-modal');
		const openQuiz = () => {
			if (this.matDialog.getDialogById('quiz-modal')) {
				return;
			}

			this.matDialog.open(QuizModalComponent, {
				...this.modalService.getConfig(QuizModalComponent.name),
				scrollStrategy: this.overlay.scrollStrategies.noop(),
			});

			this.quizStatuses.iv ||= !this.isDesignYourBlind;
			this.quizStatuses.dyb ||= this.isDesignYourBlind;

			this.sessionStorageService.setBlindData(this.quizStatuses, STORAGE_NAMES.zip_quiz_status);
		};

		const storageStatuses = this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_quiz_status);
		const currentIsSampleProject = !!this.sessionStorageService.getBlindData(STORAGE_NAMES.zip_image_visualisation_background)?.type;
		this.quizStatuses = storageStatuses || this.quizStatuses;
		const DYBQuizNotShowed = !this.quizStatuses.dyb && this.isDesignYourBlind;
		const IVQuizNotShowed = !this.quizStatuses.iv && !this.isDesignYourBlind;

		if (quizModal && currentIsSampleProject !== this.isSampleProject && this.isSampleProject !== null) {
			quizModal.close();
			this.quizStatuses.iv = false;
			quizModal.afterClosed().subscribe(openQuiz);
			return;
		}

		if (DYBQuizNotShowed || IVQuizNotShowed) {
			quizModal?.close();

			if (quizModal) {
				quizModal.afterClosed().subscribe(openQuiz);
			} else {
				openQuiz();
			}
		}

		this.changeDetection.markForCheck();
	}

	onViewChange(type: string): void {
		this.sessionStorageService.setBlindData(type, STORAGE_NAMES.zip_view_type);

		if (type !== this.viewType || !this.isDesignYourBlind) {
			this.showQuiz();
		}

		setTimeout(() => {
			this.isDesignYourBlind = type === VIEW_TYPES.design;
			this.passDataService.isLiveOpened = this.isDesignYourBlind ? false : this.passDataService.isLiveOpened;

			if (this.isDesignYourBlind) {
				this.engineService.closeVideoStream();
			} else {
				this.openRotateDeviceModal();
			}

			this.changeDetection.markForCheck();
		});

		this.viewType = type;
	}

	modelViewerLoaderHandler(status: boolean): void {
		document.querySelector(SELECTORS.model_viewer).classList.toggle('loader', status);
	}

	loadingSwitcher(status: boolean): void {
		document.querySelector(SELECTORS.wrapper).classList.toggle(CLASSES.loading, status);
	}

	openRotateDeviceModal(): void {
		const isOpen = this.matDialog.getDialogById('rotate-device-modal');
		const isPhoneLandscape =
			this.backgroundService.getScreenOrientation() === 'landscape' && window.innerHeight <= 500 && window.innerWidth > window.innerHeight;

		if (!isOpen && this.isMobile && isPhoneLandscape) {
			this.matDialog.open(
				RotateDeviceModalComponent,
				this.modalService.getConfig(RotateDeviceModalComponent.name, ROTATE_TYPES.to_portrait),
			);
		}
	}
}
