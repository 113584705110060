<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="closeDialog()"></div>
	<div class="mc-retailer-popover-info">
		<div class="mc-retailer-popover-info-column">
			<div class="mc-retailer-popover-info-item">
				<div class="mc-retailer-popover-icon outdoor"></div>
				<div class="mc-retailer-popover-info-item-text">
					{{ 'modals.retailer_popover_info.items.outdoor' | translate }}
				</div>
			</div>
			<div class="mc-retailer-popover-info-item">
				<div class="mc-retailer-popover-icon interior"></div>
				<div class="mc-retailer-popover-info-item-text">
					{{ 'modals.retailer_popover_info.items.interior' | translate }}
				</div>
			</div>
			<div class="mc-retailer-popover-info-item">
				<div class="mc-retailer-popover-icon showroom"></div>
				<div class="mc-retailer-popover-info-item-text">
					{{ 'modals.retailer_popover_info.items.showroom' | translate }}
				</div>
			</div>
			<div class="mc-retailer-popover-info-item">
				<div class="mc-retailer-popover-icon mobile"></div>
				<div class="mc-retailer-popover-info-item-text">
					{{ 'modals.retailer_popover_info.items.mobile' | translate }}
				</div>
			</div>
		</div>
		<div class="mc-retailer-popover-info-column">
			<div class="mc-retailer-popover-info-item">
				<div class="mc-retailer-popover-icon service"></div>
				<div class="mc-retailer-popover-info-item-text">
					{{ 'modals.retailer_popover_info.items.service' | translate }}
				</div>
			</div>
			<div class="mc-retailer-popover-info-item">
				<div class="mc-retailer-popover-icon swiftee"></div>
				<div class="mc-retailer-popover-info-item-text">
					{{ 'modals.retailer_popover_info.items.swiftee' | translate }}
				</div>
			</div>
		</div>
		<hr class="mc-retailer-popover-info-separator" />
		<div class="mc-retailer-popover-info-footer">
			{{ 'modals.retailer_popover_info.text' | translate }}
		</div>
	</div>
</div>
